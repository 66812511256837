/*global google*/
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { getActivePackage } from '../../service/index';
import './NetworkCoverage.scss';
import { cdnImage } from "../../helper/constants";
import { FOUR_G, FIVE_G,TWO_G,THREE_G } from "../../helper/constants";
import Loader from "../Loader/Loader";
import { useLoading } from "../../cache/context/LoadingProvider";
import { useLocation } from 'react-router-dom';
import { generateEventPageName } from "../../helper/helper";
import { sendEvent } from "../../service/eventService";
import { getNetworkTranslation } from "../../service/index";
import { useLanguage } from "../../cache/context/LanguageProvider";


const NetworkCoverage = () => {
  const [currentLoc, setCurrentLoc] = useState({ lat: parseFloat(process.env.REACT_APP_LAT), lng: parseFloat(process.env.REACT_APP_LNG) });
  const [networks, setNetworks] = useState();
  const [listOfMarker, setListOfMarkers] = useState([]);
  const [map, setMap] = useState({});
  const [isData, setIsData] = useState(false);
  const [techSelected,setTechSelected]=useState('')
  const { showLoader, hideLoader } = useLoading();
  const location = useLocation();
  const [_2Gservice, setTwoGService ] = useState();
  const [ _3Gservice, setthreeGService ] = useState();
  const [ _4Gservice, setfourGService ] = useState();
  const [ _5Gservice, setfiveGService ] = useState();
  const [networkTrans,setNetworkTranslation]= useState({
    two_g:'2G coverage',
    three_g:'3G coverage',
    four_g:'4G coverage',
    five_g:'5G coverage'
  });
  const { languagePrefix, selectedLanguage } = useLanguage();
  
  useEffect(() => {
    getCurrentLocation();
    loadMap();
    fetchTranslation();
    sendEvent({
      "eventType": "page_view",
      "page_name": generateEventPageName(location.pathname)
    })
  }, [selectedLanguage])

  const fetchTranslation =()=>{
    getNetworkTranslation(languagePrefix).then(res => {
      if (res?.data?.packageHtml) {
        setNetworkTranslation(JSON.parse(res?.data?.packageHtml));
      }
    })
  }
 
  const getCurrentLocation = () => {
    showLoader();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let pos = {
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude
        };
        if (pos.lat && pos.lng) {
          setCurrentLoc({ ...currentLoc, lat: pos.lat, lng: pos.lng })
        }
        // getNearByStores(pos.lat,pos.lng);

      }, (err) => {
        console.info(err);
      });
    }
  }

  function addMarkers(networkData, map) {
    let infoWindow = new google.maps.InfoWindow();
    let Latlngbounds = new google.maps.LatLngBounds();
    //Looping through the Array and adding Markers.
    for (var i = 0; i < networkData.length; i++) {
      var data = networkData[i];
      var myLatlng = new google.maps.LatLng(data?.latitude, data?.longitude);
      //Initializing the Marker object.
      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title: data?.title,
        icon: {
          url: data?.technology?._5Gservice ?  `${cdnImage}assets/images/map/5g-triangleone.png`:  `${cdnImage}assets/images/map/triangle.png`
        },
      });
      marker.icon.scaledSize = new google.maps.Size(15, 30);
      if (data?.technology?._5Gservice) {
        const circle = new google.maps.Circle({
          map: map,
          fillColor: '#AA0000',
          strokeColor: "#fd7d787a",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#fd7d787a",
          fillOpacity: 0.35,
          radius: 300,
        });
        circle.bindTo('center', marker, 'position');
      }

      //Plotting the Marker on the Map.
      Latlngbounds.extend(marker?.position);
      setListOfMarkers([...listOfMarker, marker]);
    }
  }


  const loadMap = (networks = []) => {
    let mapInstance = new google.maps.Map(document.getElementById('map'), {
      zoom: 9,
      center: {
        lat: parseFloat(process.env.REACT_APP_LAT),
        lng: parseFloat(process.env.REACT_APP_LNG)
      },
      fullscreenControl: false
    });
    setMap({ ...map, ...mapInstance });
    if (!networks.length) {
      getActivePackage(selectedLanguage).then(resp => {
        let data = (JSON.parse(resp?.data?.packageHtml));
        setNetworks(data);
        addMarkers(data, mapInstance);
        setIsData(true)
        toggleServices(data);
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        hideLoader();
      })
    } else {
      addMarkers(networks, mapInstance);
    }
    addSearchBox(mapInstance)
  }
  const filterTechnology = (e) => {
    let data = networks?.filter(network => network?.technology[e?.target?.value]);
    loadMap(data);
    setTechSelected(e?.target?.value)
  }

  function addSearchBox(map) {
    let input;
    input = document.getElementById('autocomplete');
    var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(24.414950, 67.778582), new google.maps.LatLng(28.040770, 98.012957));
    let autocomplete = new google.maps.places.SearchBox(input, {
      bounds: defaultBounds
    });
    autocomplete.addListener('places_changed', function () {

      document.getElementById('autocomplete').blur();
      var latOnSearch = map?.getCenter().lat();
      var lngOnSearch = map?.getCenter().lng();
      var places = autocomplete.getPlaces();
      if (places?.length === 0) {
        var geocoder = new google.maps.Geocoder();
        geocodeAddress(geocoder, map);
      } else {
        if (places[0].geometry) {
          latOnSearch = places[0]?.geometry?.location?.lat();
          lngOnSearch = places[0]?.geometry?.location?.lng();
          map.panTo(places[0]?.geometry?.location);
        }
      }
      map.setZoom(15);
    });
  }
  function toggleServices(data){
    let twoGdata = data?.find(network => network?.technology['_2Gservice']);
    let threeGdata = data?.find(network => network?.technology['_3Gservice']);
    let fourGdata = data?.find(network => network?.technology['_4Gservice']);
    let fiveGdata = data?.find(network => network?.technology['_5Gservice']);
    if(twoGdata){
      setTwoGService(true)
    } 
    if(threeGdata){
      setthreeGService(true)
    }
    if(fourGdata){
      setfourGService(true)
    }
    if(fiveGdata){
      setfiveGService(true)
    }
  }
  return (
    <>
      {!isData && <Loader></Loader>}
      <div className="location-wrapper">
        <div className="location">
          <input id="autocomplete" name="autocomplete" placeholder="Enter a Location" type="text" />
          <a className="button icon"  >
            <i className="icon-outlined-magnifier"></i>
          </a>
        </div>
        <div id='map'></div>
        <div className="container">
          <div className="radio-container horizontal radios field">
           { _2Gservice &&<label><input  type='radio' name='tech' value='_2Gservice' onChange={(e) => filterTechnology(e)} />
              <span className="radio"></span>
              <span>{networkTrans?.two_g}</span>
            </label>}
           {_3Gservice && <label>  <input type='radio' name='tech' value='_3Gservice' onChange={(e) => filterTechnology(e)} />
              <span className="radio"></span>
              <span>{networkTrans?.three_g}</span>
            </label>}
           {_4Gservice &&  <label>  <input type='radio' name='tech' value='_4Gservice' onChange={(e) => filterTechnology(e)} />
              <span className="radio"></span>
              <span>{networkTrans?.four_g}</span>
            </label>}
           { _5Gservice && <label> <input type='radio' name='tech' value='_5Gservice' onChange={(e) => filterTechnology(e)} />
              <span className="radio"></span>
              <span>{networkTrans?.five_g}</span>
            </label>}
          </div>
        </div>
      </div>

    </>

  )
}
export default NetworkCoverage;