import { useEffect, useState, useRef } from "react";
import { useCache } from "./cacheProvider";
import api from "../interceptor/api";
import { useLoading } from "./context/LoadingProvider";
import { useLanguage } from "./context/LanguageProvider";

export default function useFetch({ key, initialEnabled = true, cache, ...axiosConfig }) {
    const [error, setError] = useState();
    const { getCache, setCache } = useCache();
    const { showLoader, hideLoader } = useLoading();
    const { selectedLanguage } = useLanguage();
    const [apiCount, setAPICount] = useState(0);
    const isFirstRender = useRef(true); // Track first render

    const cacheKey = `${key}_${selectedLanguage}`;

    const [data, setData] = useState(() => {
        const savedData = getCache(cacheKey);
        return savedData ? (savedData) : undefined;
    });

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // Prevents fetching on first render if data exists
            return;
        }

        const cachedData = getCache(cacheKey);
        if (!cachedData) {
            refetch(true); // Only fetch if data isn't cached
        }else {
            refetch(false)
          }
    }, [selectedLanguage]);

    const refetch = (hard = false) => {
        setError(undefined);
        if (!hard) {
            const storedData = getCache(cacheKey);
            if (storedData) {
                setData((storedData));
                return;
            }
        }
        showLoader();
        api.request(axiosConfig)
            .then((response) => {
                let count = apiCount;
                setAPICount(count++);
                const parsedData = JSON.parse(response?.data?.packageHtml);
                setData(parsedData);
                sessionStorage.setItem(cacheKey, JSON.stringify(parsedData)); // Store in sessionStorage
                if (cache?.enabled) setCache(cacheKey, parsedData, cache?.ttl);
            })
            .catch((err) => {
                hideLoader();
                setError(err);
            })
            .finally(() => {
                let count = apiCount;
                if (count > 0) count--;
                if (!count) hideLoader();
                setAPICount(count--);
            });
    };
    return { data, error, refetch };
}