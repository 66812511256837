import React, { useState, useEffect } from "react";
import { getOtpV1 } from "../../../shared/service";
import AtOtpModal from "../AtOtpModal";
import { intiatePayment, processTrans } from "../../../shared/service";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET, ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const AirtimePayment = ({}) => {
  const [formData, setFormData] = useState({ email: "", mobileNo: "" });
  const [txnInfo, setTxnInfo] = useState({});
  const [refId, setRefId] = useState("");
  const [showOtpForAll, setShowOtpForAll] = useState(false);
  const [showProceed, setShowProceed] = useState(false);
  const [errorMobileNo, setErrorMobileNo] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [isDirtyMobileField, setIsDirtMobileField] = useState(false);
  const [isDirtyEmailField, setIsDirtyEmailField] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [uniqueCodes, setUniqueCodes] = useState([]);
  const { showLoader, hideLoader } = useLoading();
  const [toastMsg ,setToastMsg]=useState(null);
  const [translation, setTranslation] = useState({
    howWillYouPay: "How will you pay",
    insufficientBalanceMsg: "Looks like you don't have sufficient balance.",
    recharge: "Recharge airtime",
    or: "or",
    tryDiffPaymentMethod: "try different payment method",
    cancel: 'Cancel',
    sendOtp: "Send OTP",
    proceed: "Proceed",
  });
  const navigate = useNavigate();
  const { languagePrefix } = useLanguage();

  const { data: data } = useFetch({
    url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
    method: GET,
    key: TRANSLATION,
    initialEnabled: false,
    cache: {
      enabled: true,
      ttl: 3600
    }
  });

  useEffect(() => {
    setTranslation(data?.payment?.airTimePayment)
  }, [data])


  const handleChange = (e) => {
    setInsufficientBalance(false)
    const { name } = e.target;
    let value = e?.target?.value;
    setFormData({ ...formData, [name]: value });
    if (name === "mobileNo") {
      setIsDirtMobileField(true);
      if (isDirtyMobileField && value?.length == 0) {
        setErrorMobileNo("Please enter Phone No");
        return;
      }
      const isValidNumber = /^\d*$/.test(value);
      if (!isValidNumber) {
        setErrorMobileNo("Please enter valid phone no");
      } else {
        setErrorMobileNo("");
      }
    }
    if (name == "email") {
      setIsDirtyEmailField(true);
      if (isDirtyEmailField && value?.length == 0) {
        setErrorEmail("Email id is required");
        return;
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setErrorEmail("Please enter valid email");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleCancel = () => {
    navigate(txnInfo.redirectPage)
    eventEmitter('ORP','Airtime Payment Cancel Clicked','click','Airtime Payment Cancel Clicked',null);

  };
  const showToastMsg= (type,message)=>{
    setToastMsg({type,message});
    setTimeout(()=>setToastMsg(null),10000);
  }
  const handleSendOtp = () => {
    let postRequestData = {
      msisdn:
        process.env.REACT_APP_X_COUNTRY?.toLowerCase() == "ga"
          ? "0" + formData.mobileNo
          : formData.mobileNo,
    };
    let postRequest = {
      data: postRequestData,
    };
    showLoader();
    getOtpV1(postRequest).then(
      (res) => {
        // this.loaderService.stopLoading();
        let data;
        res = res?.data;
        hideLoader();
        if (
          res &&
          (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
        ) {
          //   log.debug("res", res);
          setRefId(res?.result?.refId);
          setShowOtpForAll(true);
          setShowProceed(true);
          setIsValidOtp(false);
          showToastMsg('Success',res?.message || res?.result.message )
          eventEmitter('ORP','Airtime Payment send Otp Success','click','Airtime send Otp Success',null);
        } else {
          showToastMsg('Error',res?.message || "Something Went Wrong!")
          eventEmitter('ORP','Airtime Payment send Otp Failed','click',res?.message,null)
        }
      },
      (error) => {
        hideLoader()
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','Postpaid Bill Payment send Otp Failed','click',error?.error?.statusCode?.toString(),null);
        // this.loaderService.stopLoading();
        // let toastData = {
        //   msg:
        //     error.error.message ||
        //     error.error.resp.message ||
        //     "something went wrong!",
        // };
        // this._toaster.Error(
        //     'Error',toastData.msg);
      }
    );
  };

  useEffect(() => {
    let txnInfo = sessionStorage.getItem("queryData");
    txnInfo = JSON.parse(txnInfo);
    setTxnInfo({ ...txnInfo });
    setFormData({
      ...formData,
      mobileNo:
        process.env.REACT_APP_X_COUNTRY?.toLowerCase() == "ga"
          ? txnInfo.msisdn.substring(1)
          : txnInfo.msisdn,
      email: txnInfo.emailId || "",
    });
    // if(this.txnInfo && this.txnInfo.enableDualCurrency){
    //     this._subscribeToCurrencyInfo();
    //   }
    //   this.setTimerSubscription();
  }, []);

  const handleModalToggle = () => {
    setShowOtpForAll(false);
    setShowProceed(false);
  };

  const handleShowProceed = (value) => {
    setShowProceed(value);
  };

  const handleInsufficentBalance = (value) => {
    console.log("handlinbg xata  from insuccifient event ", value);
    setInsufficientBalance(value);
  };

  const handleInitiateTrxn = () => {
    let postRequestData = {
      paymentMobile:
        process.env.REACT_APP_OPCO?.toLowerCase() == "ga"
          ? "0" + formData.mobileNo
          : formData.mobileNo,
      amount: txnInfo?.amount, //Esb api object
      countryCode: process.env.REACT_APP_COUNTRY_CODE || "234",
      emailId: formData.email || txnInfo?.email,
      mobileNumber: txnInfo?.msisdn,
      currencyCode: process.env.REACT_APP_CURRENCY,
      productCode: txnInfo?.productCode || null,
      transactionType: txnInfo?.transactionType,
      unit: txnInfo?.amount,
      loggedInNumber:
        (txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.accountNo) || null,
      accountId:
        (txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.accountNo) || null,
      invoiceNumber:
        (txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.invoiceNo) || null,
      accountType:
        txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.accountNo
          ? "ENTERPRISE"
          : null,
      paymentMode: "AIR_TIME",
      uniqueCode: uniqueCodes,
    };
    let postRequest = {
      data: postRequestData,
    };
    showLoader();
    eventEmitter('ORP','Airtime Payment Continue To Pay Clicked','click','Airtime Payment Continue To Pay Clicked',null);
    intiatePayment(postRequest)
      .then((res) => {
        hideLoader();
        res = res?.data;
        if (
          res &&
          (res.statusCode == 200 || res.status.toLowerCase() == "success")
        ) {
          res?.result?.transactionId
            ? processTrxn(res?.result?.transactionId)
            : console.error("error txn id not found", res);
            eventEmitter('ORP','Initiate Airtime Payment Success','click','Initiate Airtime Payment Success',null);
        } else {
          console.log("error");
          showToastMsg('Error',res?.message || "Something Went Wrong!")
          eventEmitter('ORP','Initiate Airtime Payment Failed','click',res?.statusCode ? res?.statusCode?.toString() : 500  || res.message,null);
        }
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','Initiate Airtime Payment Failed','click',error?.error?.toString(),null);
      });
  };

  const processTrxn = (txnId) => {
    let postRequestData = {
      transactionId: txnId,
      paymentMobile:
        process.env.REACT_APP_OPCO == "ga"
          ? "0" + formData.msisdn
          : formData.msisdn,
      isSubscriberRequest: true,
      paymentMode: "AIR_TIME",
      proccessFlag: true,
    };

    let postRequest = {
      data: postRequestData,
    };
    showLoader();
    processTrans(postRequest)
      .then((res) => {
        hideLoader();
        res = res?.data;
        navigateToStatusPage(res.result.transactionId);
        if (
          res &&
          (res.statusCode == 200 || res.status.toLowerCase() == "success")
        ) {
          setShowOtpForAll(false);
          if (res.result.transactionId) {
            navigateToStatusPage(res?.result?.transactionId);
            eventEmitter('ORP','Process Airtime Payment Success','click','Process Airtime Payment Success',null);
          }
          else {
           showToastMsg('Error',res?.message || "Something Went Wrong!")
           eventEmitter('ORP','Process Airtime Payment Failed','click',res?.statusCode ? res.statusCode?.toString() : 500,null);
          }
        }
      })
      .catch((error) => {
        hideLoader();
        setShowOtpForAll(false);
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','Process Airtime Payment Failed','click',error?.error?.toString(),null);
      });
  };

  const navigateToStatusPage = (txnId) => {
    if (txnId) {
      // this._router.navigateByUrl("/payment/status?id=" + txnId);
      navigate("/payment/status?id=" + txnId, { replaceUrl: true });
      return;
    }
    console.log("Error while routing to status page", txnId);
  };

  const handleUniqueCodesEvent = (data) => {
    setUniqueCodes(data);
  };

  return (
    <>
      <div className="card-pay-container">
        <h3>{translation?.howWillYouPay}</h3>

        <div className="card-pay-footer airtime_payment_form">
          <div className="card-pay-form">
            <img src="assets/icons/email.svg" />
            <input
              autoComplete="off"
              type="text"
              name="email"
              placeholder="Email id"
              //add validation
              value={formData.email}
              required
              pattern="^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,}$"
              className=""
              onChange={handleChange}
            />
            <p className="error"> {errorEmail}</p>
          </div>
          <div className="card-pay-form card-pay-form-phone">
            <img src="assets/icons/phone.svg" />
            <input
              autoComplete="off"
              type="text"
              name="mobileNo"
              placeholder="Enter the Phone No"
              //add validation
              value={formData.mobileNo}
              required
              pattern="^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,}$"
              className=""
              onChange={handleChange}
              maxLength={process.env.REACT_APP_NUMBER_MAX_LENGTH}
            />
            <p className="error"> {errorMobileNo}</p>
            <p className="error">
            {insufficientBalance && (
              
               <> {translation?.insufficientBalanceMsg}<br></br> <strong>{translation?.recharge} </strong>
                {translation?.or} <strong>{translation?.tryDiffPaymentMethod}</strong> </> 
             
            )}
          </p>
          </div>

          
        </div>

        <div className="card-pay-payment-button">
          <button className="cancel-btn" onClick={handleCancel}>
            {translation?.cancel}
          </button>
          {!showProceed && (
            <button className="continue-btn" onClick={handleSendOtp} disabled={!formData.mobileNo || errorEmail || errorMobileNo}>
             {translation?.sendOtp}
            </button>
          )}
        {showProceed && <button disabled={insufficientBalance}>{translation?.proceed}</button>}
        </div>

        {showOtpForAll && (
          <AtOtpModal
            txnInfo={txnInfo}
            refId={refId}
            toggleModalEvent={handleModalToggle}
            sendShowProceedEvent={handleShowProceed}
            insufficientBalanceEvent={handleInsufficentBalance}
            sendInitiateEvent={handleInitiateTrxn}
            sendUniqueCodesEvent={handleUniqueCodesEvent}
            formInput={formData}
          />
        )}
      </div>
      {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
    </>
  );
};
export default AirtimePayment;
