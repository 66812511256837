import { useEffect, useState } from 'react'
import { debounce } from "../../helper/helper";
import "./style.scss";
import { getSearchTranslation } from "../../service";
import { useLanguage } from '../../cache/context/LanguageProvider';

const Search = ({ questions, selectQuestion }) => {
  const [keyWord, setKeyWord] = useState(""),
    [searchList, setSearchList] = useState([]);
  const [searchTranslation, setSearchTranslation] = useState({});
  const { languagePrefix } = useLanguage();

  useEffect(() => {
    fetchTranslation()
  }, [languagePrefix])

  const fetchTranslation = () => {
    getSearchTranslation(languagePrefix).then(res => {
      if (res?.data?.packageHtml){
        setSearchTranslation(JSON.parse(res?.data?.packageHtml));
      }
    })
  }

  const debounceFilter = debounce(filterList, 300);
  
  /**
   * This function is used to handle the search keyword input by the user.
   * This function also handle the filtering of data.
   * @param {*} value 
   */
  const handleInputChange = (value = "") => {
    setKeyWord(value);
    debounceFilter(value);
    if (!value)
      setSearchList([]);
  }

  /**
   * @param {*} key
   * This params for this function is the search keyword entered by the user.
   * This function is used to filter out the questions which have matching keyword entered by the user.
   */
  function filterList(key = "") {
    let data = [...questions], result = [];
    if (key) {
      data.forEach((item) => {
        if (item?.question?.toLowerCase()?.includes(key))
          result.push(item);
      });
    }
    else result = [];
    setSearchList(result);
  }


  function wrapAllMatchingCharacters(inputString = "") {
    // Create a regular expression with the "g" flag to find all occurrences
    // of the searchString in the inputString, while ignoring case.
    const regex = new RegExp(keyWord, "gi");

    // Use the replace method with a callback function to wrap all matching characters
    // in <strong> tags.
    const resultString = inputString?.replace(regex, match => `<strong>${match}</strong>`);
    return resultString;
  }

  return (
    <div className="search-main-ctn">
      <div className="search-field">
        <input type="text" value={keyWord} placeholder={searchTranslation?.question} onChange={(e) => debounce(handleInputChange(e.target.value), 1000)} />

      </div>
      <button className="link link-arrow">
        {searchTranslation?.search}
      </button>
      {!!searchList.length ?
        <div className="suggestion-list">
          {searchList.map((item, i) => {
            return (
              <div className="suggestion" onClick={() => selectQuestion(item)} key={i} dangerouslySetInnerHTML={{ __html: wrapAllMatchingCharacters(item.question) }} />
            )
          })}
        </div>
        :
        <>
          {!!keyWord &&
            <div className="no-data-found">
              {searchTranslation?.NoData}
            </div>

          }
        </>
      }

    </div>
  )
}

export default Search;