import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { checkAMstatus } from "../../../shared/service";
import { useNavigate } from "react-router-dom";
import { getPaymentStatus } from "../../../shared/service";
import './PaymentStatus.scss'
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET, ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";


const PaymentStatus = ({ }) => {
    const [id] = useSearchParams();
    const [maxRetry, setMaxRetry] = useState(5);
    const [counter, setCounter] = useState(0);
    const [transactionStatus, setTransactionStatus] = useState(false);
    const [completeTransactionInfo, setCompleteTransactionInfo] = useState();
    const [txnInfo, setTxnInfo] = useState();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoading();
    const [toastMsg, setToastMsg] = useState(null);
    const [translation, setTranslation] = useState({
        dearCustomer: "Dear Customer",
        yourTransaction: "your transaction is ",
        return: "RETURN",
        summary: "Transaction Summary for ",
        paymentType: "Payment Type",
        bundleDescription: "Bundle Description",
        transactionStatus: "Transaction Status",
        amount: 'Amount',
        mobile: " Mobile Number",
        accountNo: "Account Number",
        invoiceNo: "Invoice Number",
    })
    const { languagePrefix } = useLanguage();
    const { data: data } = useFetch({
        url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
        method: GET,
        key: TRANSLATION,
        initialEnabled: false,
        cache: {
          enabled: true,
          ttl: 3600
        }
    });

    useEffect(() => {
        if(data?.payment?.paymentStatus)  setTranslation(data?.payment?.paymentStatus)
    }, [data])

    useEffect(() => {
        setTxnInfo(JSON.parse(sessionStorage.getItem('queryData')));
        eventEmitter('ORP', 'Payment Status Page Load', 'Page Load', 'Payment Status Page Load', null);
    }, []);

    useEffect(() => {
        let intervalStatusId;
        if (counter <= maxRetry) {
            intervalStatusId = setTimeout(() => callCheckStatus(id.get('id')), 1000);
        } else {
            hideLoader();
        }
        return () => clearTimeout(intervalStatusId); // Clear timeout on unmount or dependency change
    }, [counter, id]); // Added counter and id as dependencies to useEffect

    const showToastMsg = (type, message) => {
        setToastMsg({ type, message });
        setTimeout(() => setToastMsg(null), 10000);
    }
    const messageChange = (text, useTranslate = false) => {
        if (text === 'REQUEST_REGISTERED' || text === 'ALREADY_PROCESSED' || text === 'REQUEST_RAISED') {
            return 'In Processing';
        } else {
            return text;
        }
    }

    const callCheckStatus = (txnId) => {
        showLoader();
        checkAMstatus(txnId).then(
            (res) => {
                res = res?.data;
                if (res && (res?.statusCode == 200 || res?.status.toLowerCase() == "success")) {
                    if (res?.result && (res?.result?.status || res?.result.transactionStatus)) {

                        if (((res?.status && res?.status == "REQUEST_REGISTERED") || (res?.result.transactionStatus && res?.result.transactionStatus == "REQUEST_REGISTERED")) &&
                            counter < maxRetry) {
                            setCounter((prevCounter) => {
                                const newCounter = prevCounter + 1;
                                return newCounter;
                            });
                        } else {
                            if (res?.result?.transactionId) {
                                fetchPaymentStatus(res?.result?.transactionId);
                            }
                        }
                    }
                }
                else {
                    hideLoader();
                    showToastMsg('Error', res?.message || "Something Went Wrong!")
                }
            },
            (error) => {
                hideLoader();
                console.log(error);
                showToastMsg('Error', error?.error?.message || error?.message || "Something Went Wrong!")
            }
        );
    }

    const fetchPaymentStatus = (txnId) => {
        showLoader();
        getPaymentStatus(txnId).then(
            (res) => {
                hideLoader();
                res = res?.data;
                if (res && (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")) {
                  eventEmitter('ORP','Payment Status Fetch Success','click','Payment Status Fetch Success',null);
                    if (res && res.result) {
                        if (res.result?.status && res.result?.status?.toUpperCase() == 'SUCCESS') {
                            setTransactionStatus(false);
                        } else {
                            setTransactionStatus(true);
                        }
                        res.result.transactionType = res?.result?.transactionType == "POSTPAID_BILL" && res?.result?.invoiceNumber ? "FIXED INTERNET BILL PAYMENT" : res?.result?.transactionType;
                        res.result.transactionType = res?.result?.transactionType ? res?.result?.transactionType.replace(/\_/g, " ") : null;
                        res.result.paymentMode = res?.result?.paymentMode.replace(/\_/g, " ");
                        setCompleteTransactionInfo(res?.result);
                        if (completeTransactionInfo?.status == "ALREADY_PROCESSED") {
                            completeTransactionInfo.status = "REQUEST_REGISTERED";
                        }
                    }
                }
                else {
                    showToastMsg('Error', res?.message || "Something Went Wrong!")
                    eventEmitter('ORP','Payment Status Fetch Failed','click',res?.message,null);
                }
            },
            (error) => {
                hideLoader();
                console.log(error);
                showToastMsg('Error', error?.error?.message || error?.message || "Something Went Wrong!")
                eventEmitter('ORP','Payment Status Fetch Failed','click',error?.error?.statusCode?.toString(),null);

            }
        );
    }

    const backToHome = () => {
        navigate(txnInfo['redirectPage']);
        eventEmitter('ORP','Return Button Clicked','click','Return Button Clicked',null);

    }
    return (<>
       {(completeTransactionInfo||txnInfo) &&  <div>
            <div className='container'>
                <div className={`thankyou 
                ${completeTransactionInfo?.status.toUpperCase() == 'SUCCESS' ? 'thankyou_success' :
                        completeTransactionInfo?.status.toUpperCase() == 'FAILED' ? 'thankyou_failed' :
                            completeTransactionInfo?.status.toUpperCase() == 'REQUEST_REGISTERED' ? 'thankyou_process' : ''

                    }
        
        `}>
                    <div className="thankyou_left">
                        <div className='header-details'>
                           <span className="icons_img">
                           {completeTransactionInfo?.status.toUpperCase() == 'SUCCESS' && <img class="success" src="assets/icons/correct.svg" />}
                            {(completeTransactionInfo?.status.toUpperCase() == 'FAILED' || completeTransactionInfo?.status?.toUpperCase()== 'FALIURE') && <img class="process" src="assets/icons/close.svg" />}
                            {(completeTransactionInfo?.status.toUpperCase() == 'REQUEST_REGISTERED' ||completeTransactionInfo?.status.toUpperCase() === 'REQUEST_RAISED') && <img class="failed" src="assets/icons/process.svg" />}
                         </span>
                            <h2 className="statusColor">
                                {completeTransactionInfo?.status && completeTransactionInfo?.status.toLowerCase() == 'success' ? 'Thank you' : (messageChange(completeTransactionInfo?.status, false))}

                            </h2>
                            <p>
                               {translation?.dearCustomer} ({completeTransactionInfo?.mobileNumber}), {translation?.yourTransaction}<span className="statusColor">{completeTransactionInfo?.status && (messageChange(completeTransactionInfo?.status, true))}</span>
                            </p>
                        </div>
                        <div className="return_btn">
                        <img src="assets/icons/leftArr.svg"/>
                     <span onClick={()=>backToHome()}>{translation?.return}</span>
                     </div>

                    </div>
                    <div className="thankyou__right">
                        <h3>{translation?.summary} {completeTransactionInfo && (completeTransactionInfo?.refTransactionId || completeTransactionInfo?.transactionId)}</h3>

                        <div class="status-item-row">
                            <span>{translation?.paymentType}</span>
                            <span>
                                {
                                    completeTransactionInfo &&
                                    completeTransactionInfo?.transactionType
                                }</span>
                        </div>
                        {txnInfo?.bundleInfo && <div class="status-item-row" >
                            <span>{translation?.bundleDescription}</span>
                            <span>
                                {
                                    txnInfo &&
                                    txnInfo?.bundleInfo?.description
                                }</span>
                        </div>}
                        <div class="status-item-row">
                            <span>{translation?.transactionStatus}</span>
                            <span className="statusColor">
                                {
                                    (transactionStatus ? (completeTransactionInfo?.status === 'REQUEST_REGISTERED' ||completeTransactionInfo?.status === 'REQUEST_RAISED' )? 'In Processing' : 'Unsuccessful' : 'Successful')
                                }
                            </span>
                        </div>
                        <div class="status-item-row">
                            <span>{translation?.amount}</span>
                            <span>
                                {completeTransactionInfo && completeTransactionInfo?.currencyCode}{' '}
                                {
                                    completeTransactionInfo && completeTransactionInfo?.amount
                                }</span>
                        </div>
                        <div class="status-item-row">
                            <span>{translation?.mobile}</span>
                            <span>{
                                completeTransactionInfo && completeTransactionInfo?.msisdn || completeTransactionInfo?.mobileNumber
                            }</span>
                        </div>

                        {completeTransactionInfo?.accountId && <div class="status-item-row">
                            <span>{translation?.accountNo}</span>
                            <span>{
                                completeTransactionInfo && completeTransactionInfo?.accountId
                            }</span>
                        </div>}

                        {completeTransactionInfo?.invoiceNumber && <div class="status-item-row" >
                            <span>{translation?.invoiceNo}</span>
                            <span>{
                                completeTransactionInfo && completeTransactionInfo?.invoiceNumber
                            }</span>
                        </div>}
                        {completeTransactionInfo?.statusReason && <div class="status-item-row" >
                            <span>*{
                                completeTransactionInfo && completeTransactionInfo?.statusReason
                            }</span>
                        </div>}
                    </div >
                </div>
            </div>
        </div>}
        {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={() => setToastMsg(null)}></ToastMsg>}
    </>)
}




export default PaymentStatus