import React, { useState, useEffect } from "react"
import { fetchConfiguration } from "../../../shared/service";
import { getLineType, getInvoice } from "../../../shared/service";
import { RECHARGE_TYPE } from "../../../enums/recharge-type.enum";
import { useNavigate } from "react-router-dom";
import './FixedRecharge.scss'
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { FIXED_REXHARGE_TRANSLATION } from "../../../helper/constants";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET ,ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const FixedRecharge = () => {
  const [formData, setFormData] = useState({ account_number: '', email: '' })
  const [emailError, setEmailError] = useState('');
  const [accountError, setAccountError] = useState('');
  const [invoiceDetailsList, setInvoiceDetailsList] = useState([])
  const [lineNumber, setLineNumber] = useState()
  const [contactPersonMsisdn, setContactPersonMsisdn] = useState();
  const [translation, setTranslation] = useState({
    title: "Fixed Internet",
    getYourPaymentDone: "Let’s get your payment done",
    accountNo: "Account Number ",
    registeredEmail: " Registered account Email id",
    showBills: "SHOW BILLS",
    invoice: "Invoice Number",
    dueDate: "Due Date",
    duration: " Duration",
    amount: "Amount",
    overdue: "overdue",
    payBill: "PAY BILL"
  })
  // const [activeClass, setactiveClass] = useState(false);
  // const [activeEmailClass, setEmailactiveClass] = useState(false);


  const { showLoader, hideLoader } = useLoading();
  const [toastMsg, setToastMsg] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchConfig();
    eventEmitter('ORP', 'Fixed Internet Payment Page Load', 'page load', 'Fixed Internet Page Load', null);
  }, [])
  const { languagePrefix } = useLanguage();
  const { data: data } = useFetch({
    url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
    method: GET,
    key: TRANSLATION,
    initialEnabled: false,
    cache: {
      enabled: true,
      ttl: 3600
    }
  });
  useEffect(() => {
    if(data?.fixedRecharge) setTranslation(data?.fixedRecharge)
  }, [data])

  const handleChange = (value, event) => {
    const { name } = event?.target;
     setFormData({ ...formData, [name]: value })
    if (value) {
      // setactiveClass(true)
      if (!formData.email) {
        setEmailError('')
      }
      else if (name == 'email' && event.target.checkValidity()) {
        setEmailError('')
      }
      else if (name == 'email') {
        setEmailError('Invalid Email Id')

      }
      if (!formData.account_number) {
        setAccountError('')
      }
      else if (name == 'account_number' && event.target.checkValidity()) {
        setAccountError('')
      }
      else if (name == 'account_number') {
        setAccountError('Invalid Account Number')
        //  if(e.target.value) {
        //   setactiveClass(true)
        // }
        // else  {
        //   setactiveClass(false)
        // }
      }
    } 
    // else {
    //   setactiveClass(false)
    // }

  }

  const crossClick = (fieldName) => {
    setFormData({...formData, [fieldName]: '' })
    if(fieldName=='account_number'){
      setAccountError('')
    }
    else{
      setEmailError('')
    }
  }

  const fetchConfig = () => {
    showLoader();
    fetchConfiguration().then(
      (res) => {
        hideLoader();
        res = res?.data;
        if (
          res && (res?.statusCode == 200 || res?.status.toLowerCase() == "success")
        ) {
          if (res?.result?.configData?.payStackConfig && JSON.parse(res.result?.configData?.payStackConfig).key) {
            sessionStorage.setItem('_p_y_m_e_n_t_k_e_', JSON.parse(res.result?.configData?.payStackConfig).key, true);
          }
          if (res?.result?.configData) {
            sessionStorage.setItem('_c_o_n_f_i_g', JSON.stringify(res?.result?.configData), true);
          }
          eventEmitter('ORP', 'Postpaid Bill Payment Fetch Config Success', 'click', 'Postpaid Recharge Fetch Config Success', null);
        }
        else {
          showToastMsg('Error', res?.message || "Something Went Wrong!")
          eventEmitter('ORP', 'Postpaid Bill Payment Fetch Config Failed', 'click', res?.message, null);
        }
      })
      .catch((error) => {
        console.log(error);
        hideLoader();
        showToastMsg('Error', error?.error?.message || error?.message || "Something Went Wrong!")
        eventEmitter('ORP', 'Postpaid Bill Payment Fetch Config Failed', 'click', error?.error?.statusCode?.toString(), null);
      })
  }

  const validateAccountDetails = (event) => {
    event.preventDefault()
    showLoader();
    let requestData = {
      accountNo: process.env.REACT_APP_OPCO.toLowerCase() == "ga" ? "0" + formData.account_number : formData.account_number,
      contactPersonEmailId: formData.email
    }
    getLineType(requestData).then(
      (res) => {
        hideLoader();
        console.log(res);
        if (res?.data?.status.toLowerCase() == "success" || res?.data?.status?.toLowerCase() == 200) {
          fetchInvoices()
        } else {
          showToastMsg('Error', res?.message || "Something Went Wrong!")
        }
        eventEmitter('ORP','Postpaid Bill Payment Validate Msisdn Success','click','Postpaid Recharge Validate Msisdn Success',null);
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
        showToastMsg('Error', error?.error?.message || error?.message || "Something Went Wrong!")
        eventEmitter('ORP','Postpaid Bill Payment Validate Msisdn Failed','click',error?.error?.statusCode?.toString(),null);
      })

  }
  const fetchInvoices = () => {
    let requestData = {
      accountNo: process.env.REACT_APP_OPCO.toLowerCase() == "ga" ? "0" + formData.account_number : formData.account_number,
      contactPersonEmailId: formData.email
    }
    showLoader();
    getInvoice(requestData).then(
      (res) => {
        res = res?.data
        hideLoader();
        if (res?.statusCode == 200 || res?.status?.toLowerCase() == "success") {
          setInvoiceDetailsList(res?.result?.response?.invoices);
          setLineNumber(res?.result['lineNumber']);
          setContactPersonMsisdn(res.result['contactPersonMsisdn']);
        }
        else {
          showToastMsg('Error', res?.message || "Something Went Wrong!")
        }
        eventEmitter('ORP','Postpaid Bill Payment Validate Msisdn Success','click','Postpaid Recharge Validate Msisdn Success',null);
      })
      .catch((error) => {
        hideLoader();
        showToastMsg('Error', error?.error?.message || error?.message || "Something Went Wrong!")
        eventEmitter('ORP','Postpaid Bill Payment Validate Msisdn Failed','click',error?.error?.statusCode?.toString(),null);
      })
  }
  const onProccedRecharge = (data) => {
    console.log(data);
    let txnData = {
      msisdn: process.env.REACT_APP_OPCO.toLowerCase() == "ga" ? "0" + contactPersonMsisdn : contactPersonMsisdn,
      enableDualCurrency: process.env.REACT_APP_OPCO.toLowerCase() == "cd" ? true : false,
      emailId: formData.email,
      amount: data.outstandingAmount,
      lineType: "POSTPAID",
      transactionType: "POSTPAID_BILL",
      productCode: null,
      description: "Bill Amount",
      currency: process.env.REACT_APP_CURRENCY,
      redirectPage: '/orp/fixed-internet',
      rechargeType: RECHARGE_TYPE.BILL_PAYMENT,
      invoiceDetail: data
    };
    sessionStorage.setItem("queryData", JSON.stringify(txnData), true);
    navigate("/payment/start");
    eventEmitter('ORP','Postpaid Bill Payment Pay Bill Clicked','click','Postpaid Recharge Pay Bill Clicked',null);
  }
  const showToastMsg = (type, message) => {
    setToastMsg({ type, message });
    setTimeout(() => setToastMsg(null), 3000);
  }
  return (
    <>
      <div className="online-recharge">
        <div className="container">
          <h2>{translation?.title}</h2>
          <h4>{translation?.getYourPaymentDone}</h4>
        </div>

      </div>
      <div className="bundle-recharge-form bundle-FixedRecharge-form">
        <div className="container">
          <div className="form-container">
            <form autoComplete="off">
              <div className={`formGroup ${formData.account_number ? 'active' : ''}`}>
                <input className="formControl" type="text" name="account_number" value={formData.account_number} onChange={(event) => handleChange(event.target.value, event)} pattern="^[PE]{2}\d{10}$" />
                <label >{translation?.accountNo}</label>
                {formData.account_number  && <img
                  className="form-close"
                  src={'assets/icons/close-thin-white.svg'}
                  alt=""
                  onClick={() => crossClick('account_number')}
                />}
                <div
                  className="error-validation">
                  <p className="msg">{accountError}</p>
                </div>
              </div>
              <div className={`formGroup ${formData.email ? 'active' : ''}`}>

                <input className="formControl" type="text" name="email" value={formData.email} onChange={(event) => handleChange(event.target.value, event)} pattern="^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,}$" />
                <label>{translation?.registeredEmail}</label>
                {formData.email && <img
                  className="form-close"
                  src={'assets/icons/close-thin-white.svg'}
                  alt=""
                  onClick={() => crossClick('email')}
                />}
                <div className="error-validation">
                  <p className="msg">{emailError}</p>
                </div>
              </div>

            </form>
            <button className="buyAirtimeBtn" onClick={validateAccountDetails} disabled={ !formData?.email ||!formData?.account_number ||accountError || emailError}>{translation?.showBills}</button>
          </div>   </div> </div>

      {/* disabled={emailError.length || accountError.length} */}
      {invoiceDetailsList && invoiceDetailsList.length > 0 &&
        <div className="fixedInternet">
          <div className="container">
            <h5>{translation?.title}
            </h5>
            <div className="table-responsive">
              <div className="bundleTable">
                <div className="bundleTable__row head">
                  <div className="col"> {translation?.accountNo}</div>
                  <div className="col"> {translation?.invoice} </div>
                  <div className="col"> {translation?.dueDate} </div>
                  <div className="col"> {translation?.duration} </div>
                  <div className="col"> {translation?.amount} </div>
                  <div className="col"> </div>

                </div>
                {invoiceDetailsList.length && invoiceDetailsList.map(invoice => (
                  <div className="bundleTable__row">
                    <div className="col">{invoice.accountNo}</div>
                    <div className="col">{invoice.invoiceNo}</div>
                    <div className="col"> {invoice.dueDate}</div>
                    <div className="col">{invoice.billingStartDate} - {invoice.billingEndDate}</div>
                    <div className="col"> {invoice?.currency} {invoice?.outstandingAmount}
                      {invoice.invoiceStatus && invoice.invoiceStatus.toLowerCase() == 'overdue' && <span className="color-red" >{translation?.overdue}</span>}
                    </div>
                    <div className="col" onClick={() => onProccedRecharge(invoice)}><a>{translation?.payBill}</a></div>

                  </div>
                ))}
              </div>
            </div>
          </div>


        </div>
      }
      {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={() => setToastMsg(null)}></ToastMsg>}
    </>

  )
}

export default FixedRecharge;