import React, { useState, useEffect } from "react";
import FormContainer from "../../../shared/components/FormContainer/FormContainer";
import BundleTable from "../../../shared/components/BundleTable";
import { fetchConfiguration } from "../../../shared/service";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET ,ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const DataBundle=()=>{
    const [formData, setFormData]=useState({msisdn:''})
    const [lineTypeRes, setLineType] = useState(false);
    const [msisdnLineType, setmsisdnLineType] = useState('');
    const { showLoader, hideLoader } = useLoading();
    const [toastMsg ,setToastMsg]=useState(null);
    const [translation, setTranslation] = useState({
      recharge: "Recharge or Buy Bundles",
    })
    const navigate = useNavigate();

  useEffect(() => {
    fetchConfig();
    eventEmitter('ORP','Broadband Recharge Page Load','page load','Broadband Recharge Page Load',null);
  }, []);
  const { languagePrefix } = useLanguage();
  const { data: data } = useFetch({
    url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
    method: GET,
    key: TRANSLATION,
    initialEnabled: false,
    cache: {
      enabled: true,
      ttl: 3600
    }
  });

  useEffect(() => {
    if(data?.dataBundle) setTranslation(data?.dataBundle)
  }, [data])

    const fetchConfig=() =>{
        showLoader();
        fetchConfiguration().then(
        (res) => {
            res = res?.data;
            hideLoader();
            if (
            res && (res?.statusCode == 200 || res?.status.toLowerCase() == "success")
            ) {
            if(res?.result?.configData?.payStackConfig && JSON.parse(res.result?.configData?.payStackConfig).key){
                sessionStorage.setItem('_p_y_m_e_n_t_k_e_', JSON.parse(res?.result?.configData?.payStackConfig).key, true);
            }
            if(res?.result?.configData){
                sessionStorage.setItem('_c_o_n_f_i_g', JSON.stringify(res?.result?.configData), true);
            }
            eventEmitter('ORP','Broadband Recharge Fetch Config Success','click','Broadband Recharge Fetch Config Success',null);
            } else{
                showToastMsg('Error',res?.message || "Something Went Wrong!")
              eventEmitter('ORP','Broadband Recharge Fetch Config Failed','click',res?.message,null);

            }
            
        })
            .catch((error)=>{
            showLoader();
            showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
            console.log(error);
            eventEmitter('ORP','Broadband Recharge Fetch Config Failed','click',error?.error?.statusCode?.toString(),null);

            })
            }
    const handleChange=(e)=>{
        const{name}=e.target;
        setFormData({...formData,[name]:e.target.value})
    }

    const crossClick =()=>{
        setFormData({...formData,msisdn:''})
        setLineType(false)
       }
    const handleDataForm=(data)=>{
        setLineType(data);
        setmsisdnLineType("PREPAID")
    
       }
       const showToastMsg= (type,message)=>{
        setToastMsg({type,message});
        setTimeout(()=>setToastMsg(null),3000);
      }

      const onPackSelect = (event, bundleInfo) => {
        // event.persist();
        if (!bundleInfo) {
          return;
        }
    
        setFormData({ ...formData, amount: bundleInfo?.amount?.toString() });
        let txnData = {
          msisdn:
            process.env.REACT_APP_OPCO?.toLowerCase() == "ga"
              ? "0" + formData?.msisdn
              : formData?.msisdn,
          enableDualCurrency:
          process.env.REACT_APP_OPCO?.toLowerCase() == "cd" ? true : false,
          amount: bundleInfo?.amount,
          bundleInfo: bundleInfo,
          lineType: bundleInfo?.linetype || "PREPAID",
          productCode: bundleInfo?.productCode,
          description: "Bundle Purchase",
          currency: bundleInfo?.currency || process.env.REACT_APP_CURRENCY,
          redirectPage: "/data-bundle",
          rechargeType: "BUNDLE PURCHASE",
          transactionType:
            (bundleInfo?.lineType || msisdnLineType).toLowerCase() == "prepaid"
              ? "PREPAID_BUNDLES"
              : "POSTPAID_BUNDLES",
        };
        sessionStorage.setItem("queryData", JSON.stringify(txnData), true);
    
        navigate("/payment/start");
        eventEmitter('ORP','Prepaid Recharge Bundle Selected','click','Prepaid Recharge Bundle Selected',null);
    
      };
    return(
        <>
           <div class="online-recharge">
        <div class="container">
          <h2>{translation?.recharge}</h2>
          </div>   </div>
      
      <div className="bundle-recharge-form">
        <div className="container">
          <div className="form-container">
            <FormContainer
              formInput={formData}
              handleChange={handleChange}
              sendDataToParent={handleDataForm}
              crossClick={crossClick}
              showAmountField={false}
            ></FormContainer></div></div></div>

            {lineTypeRes && <BundleTable msisdn={formData?.msisdn} linetype={msisdnLineType}  buyBundleEvent={onPackSelect}/>}
            {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
            
        </>
    )
}
export default DataBundle
