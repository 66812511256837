import React, { useEffect, useState } from "react";
import { getBundles } from "../../service";
import "./BuyBundleTable.scss";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION, GET, ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const BundleTable = ({
  msisdn,
  selectedCat,
  linetype,
  rechargeType,
  newItemEvent,
  buyBundleEvent,
}) => {
  const [allBundles, setAllBundles] = useState([]);
  const [originaBundleList, setOrginalBundleList] = useState([]);
  const [selectedbundles, setSelectedBundles] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState("category-1");
  const [categoriesDataPacks, setCatergoryDataPacks] = useState([]);
  const [isBundleAvailable, setIsBundleAvailable] = useState(false);
  const [filteredBundleLists, setFilteredBundleLists] = useState([]);
  const [translation, setTranslation] = useState({
    clickOptions: "To Buy a bundle , click any of the options below",
    all: "All",
    bundles: "Bundles",
    benefits: "Benefits",
    validity: "Validity",
    buy: "BUY"
  })
  const { languagePrefix } = useLanguage();
  const { data: data } = useFetch({
    url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
    method: GET,
    key: TRANSLATION,
    cache: {
      enabled: true,
      ttl: 3600
    },
    initialEnabled: false,
    
  });

  useEffect(() => {
    if(data?.shared?.bundleTable)  setTranslation(data?.shared?.bundleTable)
  }, [data])

  useEffect(() => {
    // setAllBundles(bundles);
    // setSelectedBundles(bundles);
    fetchDataBundle();
  }, []);
  const fetchDataBundle = () => {
    // loaderService.startLoading();

    let postRequestData = {
      msisdn: process.env.opco?.toLowerCase() == "ga" ? "0" + msisdn : msisdn,
      lineType: process.env.opco == "UG" || process.env.opco == "CG" && rechargeType == "Broadband Recharge" ? null : linetype,
      serviceType:
        (process.env.opco == "UG" || process.env.opco == "CG") &&
          rechargeType == "Broadband Recharge"
          ? linetype
          : null,
      // serviceType: "WEB", //09/02/2022: removed as per raised by SC opco.
      // "category": AppConstants.bundleAPICategory
    };
    let postRequest = {
      data: postRequestData,
    };
    getBundles(postRequest).then(
      (res) => {
        //   loaderService.stopLoading();
        let data;
        res = res?.data;
        if (
          res &&
          (res.statusCode == 200 || res.status?.toLowerCase() == "success")
        ) {
          if (res.result && res.result.length > 0) {
            setIsBundleAvailable(true);
            setAllBundles(res.result);
            setOrginalBundleList(res.result);
            let dataPacksCategory = res?.result?.reduce((r, a) => {
              r[a.category] = [...(r[a.category] || []), a];
              return r;
            }, {});
            // dataPacksCategory?.sort(function (a, b) {
            //     if (a > b) {
            //         return 1;
            //     } else if (a < b) {
            //         return -1;
            //     }
            //     return 0;
            // });
            let keys = Object.keys(dataPacksCategory).sort();
            setCatergoryDataPacks(dataPacksCategory);
            generateSubCategories(keys[0], 0, dataPacksCategory);
            setIsBundleAvailable(true);
          } else {
            setIsBundleAvailable(false);
          }
          eventEmitter('ORP', ' Fetch Bundle Success', 'click', ' Fetch Bundle Success', null);
        } else {
          let toastData = {
            msg: res?.message || "something went wrong!",
          };
        }
        eventEmitter('ORP', rechargeType + ' Fetch Bundle Failed', 'click', res?.message, null);
      },
      (error) => {
        eventEmitter('ORP', rechargeType + ' Fetch Bundle Failed', 'click', error?.error?.statusCode?.toString(), null);
      }
    );
  };

  const generateSubCategories = (
    data = selectedCategory,
    index = 0,
    dataPacksCategory = categoriesDataPacks
  ) => {
    setActiveCategoryId("category-" + (index + 1));
    setSelectedCategory(data);
    // log.info(data, categoriesDataPacks[data]);
    let subCategoryArray = dataPacksCategory?.[data]?.map((item, index) => {
      item["validity"] =
        item["validity"] == "-1" ? "Unlimited" : item["validity"];
      return item.periodType ? item.periodType : "Others"; //|| "All"
    });

    subCategoryArray?.sort(function (a, b) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
      return 0;
    });
    let uniqueValues = new Set(subCategoryArray);
    setSubCategories([...uniqueValues]);
    onAllSubCategory(data, dataPacksCategory);
  };

  const onAllSubCategory = (data, dataPacksCategory = categoriesDataPacks) => {
    setSelectedSubCategory('');
    setFilteredBundleLists(
      dataPacksCategory?.[data]
    );
    sort(dataPacksCategory?.[data]);
  };

  const onSelectSubCategory = (data) => {
    setSelectedSubCategory(data);
    let filteredBundleListsData = categoriesDataPacks[selectedCategory].filter(
      (item) => {
        return item.periodType == data;
      }
    );

    setFilteredBundleLists(
      filteredBundleListsData && filteredBundleListsData.length > 0
        ? filteredBundleListsData
        : categoriesDataPacks[selectedCategory]
    );
    sort(filteredBundleListsData);
  };

  const sort = (filteredData) => {
    let bundleData = filteredData;
    bundleData?.sort((a, b) => {
      a = parseInt(a.validity);
      b = parseInt(b.validity);
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    setFilteredBundleLists(bundleData);
  };

  const getIconImageUrl = (categoryName) => {
    if (categoryName.includes("data")) {
      return "assets/icons/Forfait-appel.svg";
    } else if (categoryName.includes("internet")) {
      return "assets/icons/Forfait-internet.svg";
    } else if (categoryName.includes("sms")) {
      return "assets/icons/Forfait-SMS.svg";
    } else if (categoryName.includes("international")) {
      return "assets/icons/Forfait-International.svg";
    } else if (categoryName.includes("roaming")) {
      return "assets/icons/Forfait-Roaming.svg";
    } else if (
      categoryName.includes("combo") ||
      categoryName.includes("appel")
    ) {
      return "assets/icons/Forfait-appel.svg";
    } else {
      return "assets/icons/Forfait-appel.svg";
    }
  };

  return (
    <>
      {!!Object.entries(categoriesDataPacks || {})?.length && (
        <div className="buybundle_top">
          <div className="container">
            <h2> {translation?.clickOptions}</h2>
          </div>
        </div>
      )}
      <div className="bundle-categories">
        <div className="container">
          <div className="bundle-categories-tab">
            {!!Object.entries(categoriesDataPacks || {})?.length &&
              Object.entries(categoriesDataPacks || {})
                ?.sort()
                ?.map(([category, value], index) => (
                  <>
                    <div
                      className={`tab-link ${selectedCategory === category ? 'active' : ''}`}
                      key={index}
                      onClick={() => generateSubCategories(category, index)}
                    >
                      <img src={getIconImageUrl(category?.toLowerCase())} />
                      <span>{category}</span>
                    </div>
                  </>
                ))}
          </div>
        </div>
      </div>

      <div className="buybundle_section">
        <div className="container">
          <div className="buybundle_type">
            {!!subCategories?.length && (
              <span className={`${selectedSubCategory == '' ? 'active' : ''}`} onClick={() => generateSubCategories()}>
                {translation?.all}
              </span>
            )}
            {!!subCategories?.length &&
              subCategories?.map((subcategory, index) => (
                <span className={`${selectedSubCategory === subcategory ? 'active' : ''}`}
                  key={index}
                  onClick={() => onSelectSubCategory(subcategory)}
                >
                  {subcategory}
                </span>
              ))}
          </div>
          <div className="buybundle_data">
            {!!filteredBundleLists?.length && (
              <div className="buybundle_data_head">
                <div className="buybundle_data_cell"> {translation?.bundles}</div>
                <div className="buybundle_data_cell">{translation?.benefits}</div>
                <div className="buybundle_data_cell">{translation?.validity}</div>
                <div className="buybundle_data_cell">{translation?.price}</div>
                <div className="buybundle_data_cell"></div>
              </div>
            )}
            {!!filteredBundleLists?.length &&
              filteredBundleLists.map((bundle) => (
                <div className="buybundle_data_row">
                  <div className="buybundle_data_cell">
                    <div className="forfait_icon">  <img src="assets/icons/Forfait-internet.svg" /> </div>
                    <div className="bundle_desc">
                      {bundle.description}
                    </div> </div>
                  <div className="buybundle_data_cell">
                    <label> {translation?.benefits} </label>
                    <div className="bundle_dl">
                      {bundle.data} {bundle.dataUnit}
                      {bundle.dataUnit && bundle.voice ? ', ' : ''}
                      {bundle.voice} {bundle.voiceUnit}
                      {bundle.voiceUnit && bundle.sms && bundle.data ? ', ' : ''}
                      {bundle.sms} {bundle.sms ? "SMS" : ""}{" "}
                    </div>

                  </div>
                  <div className="buybundle_data_cell" >

                    <label>
                      {translation?.validity}:</label> <div className="bundle_dl">{bundle.validity} {bundle.validityUnit}

                    </div>  </div>
                  <div className="buybundle_data_cell" onClick={(event) => buyBundleEvent(event, bundle)}>
                    <div className="bundle_currency">
                      <span> {bundle.currency}</span>
                      {bundle.amount}
                    </div>
                  </div>
                  <div className="buybundle_data_cell">
                    <button
                      className="button"
                      onClick={(event) => buyBundleEvent(event, bundle)}
                    >
                      {translation?.buy}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BundleTable;
