import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  intiatePayment,
  processTrans,
  checkAMstatus,
} from "../../../shared/service";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET, ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const AirteMoneyPayment = () => {
  const [formData, setFormData] = useState({ email: "", amPhoneNo: "" });
  const [txnInfo, setTxnInfo] = useState();
  const [email, setEmailId] = useState();
  const [emailError, setEmailError] = useState("");
  const [msisdnError, setMsisdnError] = useState("");
  const { showLoader, hideLoader } = useLoading();
  const [toastMsg ,setToastMsg]=useState(null);
  const [translation, setTranslation] = useState({
    howWillYouPay: "How will you pay",
    cancel: "Cancel",
    continueToPay: "Continue to pay"
  })
  const navigate = useNavigate();
  const { languagePrefix } = useLanguage();

  const { data: data } = useFetch({
    url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
    method: GET,
    key: TRANSLATION,
    initialEnabled: false,
    cache: {
      enabled: true,
      ttl: 3600
    }
  });

  useEffect(() => {
    if(data?.payment?.amPayment) setTranslation(data?.payment?.amPayment)
  }, [data])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: e.target.value });
    if (name == "email") {
      if (value.trim() === "") {
        setEmailError("Email is required");
        return;
      }

      if (!e.target.checkValidity()) {
        setEmailError("Invalid Email Id");
      } else {
        setEmailError("");
      }
    }

    if (name == "amPhoneNo") {
      if (value.trim() === "") {
        setMsisdnError("Phone No is required");
      } else {
        setMsisdnError(""); // Clear error if input is valid
      }
    }
  };

  useEffect(() => {
    let txnInfo = sessionStorage.getItem("queryData");
    txnInfo = JSON.parse(txnInfo);
    setTxnInfo({ ...txnInfo });
    setEmailId(txnInfo?.emailId);
    setFormData({
      ...formData, amPhoneNo:process.env?.REACT_APP_X_COUNTRY?.toLowerCase() == "ga"? txnInfo?.msisdn.substring(1) : txnInfo?.msisdn,
      email: txnInfo?.emailId || "",
    });
  }, []);

  const showToastMsg= (type,message)=>{
    setToastMsg({type,message});
    setTimeout(()=>setToastMsg(null),10000);
  }

  const initiateRecharge = (event) => {
    event.preventDefault();
    showLoader();
    let postRequestData = {
      paymentMobile:
        process.env.REACT_APP_OPCO.toLowerCase() == "ga"
          ? "0" + formData.amPhoneNo
          : formData.amPhoneNo,
      amount: txnInfo.amount, //Esb api object
      countryCode: process.env.REACT_APP_COUNTRY_CODE || "234",
      currencyCode: process.env.REACT_APP_CURRENCY,
      emailId: email || "",
      mobileNumber: txnInfo?.msisdn,
      productCode: txnInfo?.productCode || null,
      transactionType: txnInfo?.transactionType,
      unit: Number(txnInfo?.amount),
      loggedInNumber:
        (txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.accountNo) || null,
      accountId:
        (txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.accountNo) || null,
      invoiceNumber:
        (txnInfo?.invoiceDetail && txnInfo?.invoiceDetail?.invoiceNo) || null,
      paymentMode: "AIRTEL_MONEY_PUSH",
    };
    let postRequest = {
      data: postRequestData,
    };

    intiatePayment(postRequest)
      .then((res) => {
        res = res?.data;
        hideLoader();
        if (
          res &&
          (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
        ) {
          res?.result?.transactionId
            ? processRecharge(res?.result?.transactionId)
            : console.error("error txn id not found", res);
            eventEmitter('ORP','Initiate AirtelMoney Payment Success','click','Initiate AirtelMoney Payment Success',null);
        } else {
          console.log("error");
          showToastMsg('Error',res?.message || "Something Went Wrong!")
          eventEmitter('ORP','Initiate AirtelMoney Payment Failed','click',res?.statusCode?.toString()  || res?.message,null);
        }
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
      });
  };
  const processRecharge = (txnId) => {
    let postRequestData = {
      paymentMobile:
        process.env.REACT_APP_OPCO.toLowerCase() == "ga"
          ? "0" + formData.amPhoneNo
          : formData.amPhoneNo,
      transactionId: txnId,
      isSubscriberRequest: true,
      paymentMode: "AIRTEL_MONEY_PUSH",
      proccessFlag: true,
    };

    let postRequest = {
      data: postRequestData,
    };
    showLoader();
    processTrans(postRequest)
      .then((res) => {
        res = res?.data;
        hideLoader();
        if (
          res &&
          (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
        ) {
          let transactionId = res?.result?.transactionId;
          transactionId
            ? callCheckStatus(transactionId)
            : console.log("Transaction ID not found in the api response", res);
           eventEmitter('ORP','Process AirtelMoney Payment Success','click','Process AirtelMoney Payment Success',null);
        } else {
          console.log("error");
          showToastMsg('Error',res?.message || "Something Went Wrong!")
          eventEmitter('ORP','Process AirtelMoney Payment Failed','click',res?.statusCode?.toString()  || res?.message,null);
        }
      })
      .catch((error) => {
        console.log(error);
        hideLoader();
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','Process AirtelMoney Payment Failed','click',error?.error?.toString(),null);
      });
  };

  const callCheckStatus = (txnId) => {
    showLoader();
    checkAMstatus(txnId).then(
      (res) => {
        res = res?.data;
        if (
          res &&
          (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
        ) {
          if (res?.result && res?.result?.transactionStatus) {
            if (
              res?.result?.transactionStatus &&
              res?.result?.transactionStatus == "IN_PROCESS"
            ) {
              let intervalId = setTimeout(() => callCheckStatus(txnId), 10000);
              return;
            } else {
              if (res?.result?.transactionId) {
                hideLoader();
                navigateToStatusPage(res?.result?.transactionId);
              }
            }
            eventEmitter('ORP','AirtelMoney Payment Check Status Success','click','AirtelMoney Payment Check Status Success',null);
          } else {
          }
        } else {
         showToastMsg('Error',res.message|| "Something Went Wrong!")
         eventEmitter('ORP','AirtelMoney Payment Check Status Failed','click',res?.message,null);

        }
      },
      (error) => {
        hideLoader();
        console.log(error);
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','AirtelMoney Payment Check Status Failed','click',error?.error?.toString(),null);

      }
    );
  };

  const navigateToStatusPage = (txnId) => {
    if (txnId) {
      navigate("/payment/status?id=" + txnId);
      return;
    }
  };

  const cancelTransaction = () => {
    navigate(txnInfo.redirectPage);
  };

  const checkMsisdn = (val, event) => {
    if (val) {
      val = val.replace(/[^0-9]/g, "");
      formData.amPhoneNo = val;
      event.target.value = val;
    }
  };

  return (
    <>
      <div className="card-pay-container">
        <h3>{translation?.howWillYouPay}</h3>
        <form autoComplete="off">
          <div className="card-pay-footer airtime_payment_form">
            <div className="card-pay-form">
              <img src="assets/icons/email.svg" />
              <input
                type="text"
                name="email"
                placeholder="Email id"
                value={formData.email}
                required
                pattern="^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,}$"
                className="entermobileno"
                onChange={handleChange}
              />
              {emailError && <p className="error">{emailError}</p>}
            </div>
            <div className="card-pay-form">
              <span className="country_code">
                +{process.env.REACT_APP_COUNTRY_CODE}
              </span>
              <input
                type="text"
                name="amPhoneNo"
                placeholder="Enter Phone No"
                value={formData.amPhoneNo}
                onChange={handleChange}
                onInput={(event) => checkMsisdn(event.target.value, event)}
                maxLength={process.env.REACT_APP_NUMBER_MAX_LENGTH}
              />
              {msisdnError && <p className="error">{msisdnError}</p>}
            </div>
          </div>
          <div className="card-pay-payment-button">
            <button onClick={cancelTransaction} className="cancel-btn">
              {translation?.cancel}
            </button>
            <button
              className="continue-btn"
              disabled={emailError || msisdnError}
              onClick={(event) => initiateRecharge(event)}
            >
              {translation?.continueToPay}
            </button>
          </div>
        </form>
      </div>
      {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
    </>
  );
};
export default AirteMoneyPayment;
