import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getHelp, getHelpDocuments, getHelpTranslation } from '../../service';
import FAQs from '../FAQs/FAQs';
import QuestionCategory from '../QuestionCategory/QuestionCategory';
import './GetHelp.scss';
import Loader from "../Loader/Loader";
import Search from '../../../Shared/Components/Search/Search';
import { ROUTES } from '../../helper/constants';
import { useNavigate } from "react-router-dom";

import { filterCategories, filterByUniqueData } from '../../helper/helper';
import { useLoading } from '../../cache/context/LoadingProvider';
import { Helmet } from 'react-helmet';
import { generateEventPageName } from '../../helper/helper';
import { sendEvent } from '../../service/eventService';
import { useLanguage } from '../../cache/context/LanguageProvider';

const GetHelp = () => {
  const [faqs, setFaqs] = useState([]);
  const [helpDocs, setHelpDocs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState('');
  const [showQuestionContent, setShowQuestionContent] = useState(false);
  const [faqRequested, setFaqRequested] = useState({});
  const [page, setPage] = useState(['category']);
  const [state, setState] = useState({ current: 1, prev: 0 })
  const [subCategory, setSubCategory] = useState('');
  const [hasData, setHasData] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [popularSearchQues, setPopularSearchQues] = useState([]);
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoading();
  const location = useLocation();
  const [helpTrans, setHelpTranslation] = useState({});
  const { languagePrefix, selectedLanguage } = useLanguage();


  useEffect(() => {
    sendEvent({
      "eventType": "page_view",
      "page_name": generateEventPageName(location.pathname)
    })
    getQuestion();
  }, [selectedLanguage])

  useEffect(() => {
    fetchTranslation();
    fetchHelpDocuments()
  }, [languagePrefix])

  const fetchHelpDocuments = () => {
    getHelpDocuments(languagePrefix).then(res => {
      setHelpDocs(JSON.parse(res.data.packageHtml))
    })
  }
  const fetchTranslation = () => {
    getHelpTranslation(languagePrefix).then(res => {
      if (res?.data?.packageHtml) {
        setHelpTranslation(JSON.parse(res?.data?.packageHtml));
      }
    })
  }

  const getQuestion = () => {
    showLoader();
    getHelp(selectedLanguage).then(response => {
      setHasData(true);
      setFaqs(response?.data);
      setPopularSearchQues(filterQuestionBypopularSearch(response?.data))
      setSearchItems(filterByUniqueData(response?.data, 'question'));
      let categorysSet = new Set();
      response?.data?.forEach(element => {
        categorysSet.add(element?.parentCategory);
      });
      setCategories([...categorysSet]);
    }).catch((e) => {
      console.log("error", e);
    })
      .finally(() => {
        hideLoader();
      })
  }

  const filterQuestionBypopularSearch = (questions) => {
    return questions.filter(ele => ele.popular?.toLowerCase() === "true")
  }

  const filterQuestionsByParentCategory = (category) => {
    let currentCategory = faqs.filter((faq) => faq.parentCategory === category)
    if (currentCategory?.length) {
      if (currentCategory[0]?.href !== 'href') {
        window.location.href = '/' + currentCategory[0]?.href;
        return;
      }
    }
    setPage([...page, 'subCategory'])
    let currentStage = state?.current;
    setState({ current: 2, prev: currentStage });
    setSelectedParentCategory(category);
    let filteredData = filterCategories(faqs, 'parentCategory', category)
    setFilteredData(filteredData);
    setSearchItems(filterByUniqueData(filteredData, 'question'))
    setShowQuestionContent(true);
  }

  const selectedContent = (content) => {
    switch (content?.category) {
      case process.env.REACT_APP_CONTACT_US:
        navigate(ROUTES.CONTACT_US);
        break;
      case process.env.REACT_APP_AIRTEL_SHOPS:
        navigate(ROUTES.AIRTEL_SHOPS);
        break;
      case process.env.REACT_APP_DEALERS:
        navigate(ROUTES.DEALERS);
        break;
      case process.env.REACT_APP_SERVICE_EXP:
        navigate(ROUTES.SERVICE_EXP);
        break;
      case process.env.REACT_APP_AIRTEL_MONEY_LOANS:
        navigate(ROUTES.AIRTEL_MONEY_LOANS);
        break;
      default:
        let filteredData = filterCategories(faqs, 'category', content?.category);
        setFilteredData(filteredData);
        setSearchItems(filterByUniqueData(filteredData, 'question'))
        setShowQuestionContent(false);
        let currState = state?.current;
        setState({ current: 3, prev: currState });
        setSubCategory(content?.category)
    }
  }

  const goToLastState = () => {
    if (state.current === 3) {
      setShowQuestionContent(true);
      setSubCategory('');
      // let filteredData = filterCategories(faqs, 'parentCategory', selectedParentCategory);
      setFilteredData(filteredData);
      setSearchItems(filterByUniqueData(filteredData, 'question'))
    }
    let prevstate = state.prev;
    setState({ current: prevstate, prev: prevstate > 0 ? prevstate - 1 : 0 })
  }

  const showAnswer = (ele) => {
    setPage([...page, 'question']);
    setFaqRequested(ele);
    let currStage = state?.current;
    setState({ current: 4, prev: currStage })
  }

  const changeStage = (ele) => {
    if (ele?.href !== 'href') {
      window.location.href = '/' + ele?.href;
    }
    else {
      setPage([...page, 'question']);
      setFaqRequested(ele);
      let currStage = state?.current;
      setState({ current: 4, prev: currStage })
    }
  }

  return (
    <>
      <Helmet>
        <title>airtel: Prepaid | Postpaid | 4G</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <meta name="title" content={"airtel: Prepaid | Postpaid | 4G"} />
        <meta name="description" content={"airtel: Prepaid | Postpaid | 4G"} />
      </Helmet>
      {state.current !== 4 && <>
        <div className="we-are-wrap">
          <div className="container">
            {state.current !== 1 && <button className='back-btn' onClick={() => goToLastState()}> <i className='icon-outlined-arrow-left'></i>Back</button>}
            <h2>{helpTrans?.hereToHelp} </h2>
            <Search questions={searchItems} selectQuestion={showAnswer} />
            {process.env.REACT_APP_OPCO === "TZ" && <>
            <div className='link-container'> 
              {!!helpDocs.length && helpDocs.map(ele=>(
                <p  class="link" >
                <a target="_blank" href={ele.link}>{ele.heading}
                <i class="icon-outlined-link-right-arrow" ></i></a></p>
              ))}
              </div>
            </>}
         
          </div> 
          
          </div>
      </>
      }
      {state.current === 1 &&
        <div className='help-wrapper'>
          <div className="container">
            <div className='row'>
              {!!categories?.length &&
                categories.map((category, index) => (
                  <>
                    <div className='col-sm-4 categories' key={index} onClick={() => filterQuestionsByParentCategory(category)}
                    >
                      <span> {category}</span>

                    </div>
                  </>
                ))}
            </div>
            <div className='faq-wrap'>
              <h3>{helpTrans?.search}</h3>
              <div className='question-container'> {popularSearchQues.map((ele, index) => {
                if (index < 10) return <a className='questions' key={index} onClick={() => showAnswer(ele)}>{ele.question}</a>
                return
              })}</div>
            </div>
          </div>
        </div>
      }
      {(state.current === 2 || state.current === 3) && !!filteredData?.length &&
        <QuestionCategory
          parentCategory={selectedParentCategory}
          subCategory={subCategory}
          categoryData={filteredData}
          showQuesContent={showQuestionContent}
          selectedContent={selectedContent}
          changeStage={changeStage}
          popularSearchQues={popularSearchQues}
        >
        </QuestionCategory>}
      {state.current === 4 && <> <FAQs faqRequested={faqRequested} goToLastState={goToLastState} getQuestion={getQuestion}></FAQs>
        <div className='need-help'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4'>
                <h3>Still need help?</h3>
              </div>
              <div className='col-sm-8 item-iconWwrap'>
                <div className='item-icon' onClick={
                  () => navigate('/store-locator')}>
                  <i className="icon icon-outlined-location"></i>
                  <span>  Find a Store</span>
                </div>
                <div className='item-icon'>
                  <i className="icon icon-outlined-customer-support"></i>
                  <span>  You can call 100</span>
                </div>
              </div>
            </div>
          </div>
        </div></>
      }
    </>)
}
export default GetHelp;