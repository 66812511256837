import { createContext, useContext } from "react";
import { getSessionData, setSessionData } from "../helper/storage";

const CacheContext = createContext(null);

export function useCache() {
  return useContext(CacheContext);
}

export default function CacheProvider({ children }) {
  const map = new Map();
/**
 * this function checks if data is cached ( stored in map) or in session storage
 * @param {*} key 
* @returns cached data if present otherwise undefined
 */
  function getCache(key) {
    let cacheValue = map.get(key);
    if (!cacheValue && !(getSessionData(key))){
        return
    }
    else{
        if(!(getSessionData(key))) return 
        cacheValue=getSessionData(key);
        cacheValue['expiry']=new Date(cacheValue?.expiry?.toString());

    }
    if (new Date().getTime() > cacheValue.expiry.getTime()) {
      map.delete(key);
      return undefined;
    }
    return cacheValue.data;
  }
  /**
   * this function is used to set data inside cache
   * @param {*} key 
   * key used to store data inside cache
   * @param {*} value 
   * value to be stored
   * @param {*} ttl 
   * expiry time of data stored
   */

  function setCache(key, value, ttl = 10) {
    var t = new Date();
    t.setSeconds(t.getSeconds() + ttl);
    map.set(key, {
      expiry: t,
      data: value
    });
    setSessionData(key, {
      expiry: t,
      data: value
    })
  }

  const contextValue = {
    getCache,
    setCache
  };

  return (
    <CacheContext.Provider value={contextValue}>
      {children}
    </CacheContext.Provider>
  );
}