import * as _ from 'lodash';
import api from '../interceptor/api';


export function getAirtelStores() {
    return api.request(`cms/maps/airtelshops?opco=${process.env.REACT_APP_OPCO}`)
}

export function searchNearByStores(lat,lng,distance,selectedLanguage){
    return api.request( `cms/maps/airtelshopsbycoordinates?opco=${process.env.REACT_APP_OPCO}&longitude=${lng}&latitude=${lat}&distanceInKilometers=${distance}`,{headers:{'lang':selectedLanguage?.toLowerCase()}})
}

export function getActivePackage(selectedLanguage){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/fetch-sites-data-5g`,{headers:{'lang':selectedLanguage?.toLowerCase()}});
}

export function getHelp(selectedLanguage) {
    return api.request(`cms/webpage/getFaqs_pCategory?opco=${process.env.REACT_APP_OPCO}`,{headers:{'lang':selectedLanguage?.toLowerCase()}});
}


export function getFeedback(id, value) {
    return api.request(`cms/webpage/likeCount/?questionId=${id}&isLike=${value}&opco=${process.env.REACT_APP_OPCO}`)
}

export function getMetaData(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/meta-data-config`)
}

export function getFAQTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/faq-translation`)
}

export function getHelpTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/getHelp-translation`)
}

export function getFooterTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/footer-translation`)
}

export function getQuesCategoryTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/question-translation`)
}

export function getReachargeTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/recharge-translation`)
}

export function getSearchTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/search-translation`)
}


export function getStoreLocatorTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/storeLocator-translation`)
}


export function getNetworkTranslation(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/network-translation`)
}

export function getHelpDocuments(languagePrefix){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=${languagePrefix}/help-documents`)
}