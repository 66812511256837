import { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return sessionStorage.getItem("selectedLanguage") || "EN";
    });
    const languagePrefix = selectedLanguage === 'EN' ? '' : '/sw';

    return (
        <LanguageContext.Provider value={{ languagePrefix, selectedLanguage, setSelectedLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}
export const useLanguage = () => useContext(LanguageContext)

