import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { getPaymentOptions } from "../../../shared/service";
import { useNavigate } from "react-router-dom";
import CardPayment from "../CardPayment/CardPayment";
import AirtimePayment from "../AirtimePayment";
import AirteMoneyPayment from "../AirtelMoneyPayment/AirtelMoneyPayment";
import PaymentStatus from "../PaymentStatus";
import "./PaymentPageLayout.scss";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import { getExchangeRates } from "../../../shared/service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET, ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const PaymentPageLayout = () => {
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [uniquePaymentOptions, setUniquePaymentOptions] = useState([]);
  const [txnInfo, setTxnInfo] = useState({});
  const [currencyConversion, setCurrencyConversion] = useState({
    usdAmount: "",
    cdfAmount: "",
  });
  const [selectedCurrencyUnit, setSelectedCurrencyUnit] = useState("");
  const [convertedAmount, setConvertedAmount] = useState();
  const [selectedPaymentOption, setSelectedPaymentOption] = useState({});
  const [pgOptions, setPgOptions] = useState();
  const [selectOption, setSelectOption] = useState("");
  const { showLoader, hideLoader } = useLoading();
  const [toastMsg, setToastMsg] = useState(null);
  const [showCurrencyToggle,setShowCurrencyToggle]=useState(false);
  const [translation, setTranslation] = useState({
    howWillYouPage: "How will you pay",
    bundlePurchase: "Bundle Purchase for",
    cards: "Debit/Credit Card",
    airtime: "Airtime",
    airtelMoney: "Airtel Money",
    mobileNo: "MOBILE NUMBER",
    planDetails: "PLAN DETAILS",
    bundle: "BUNDLE",
    benefits: "BENEFITS",
    changeCurrency: "Change Currency",
    usd: "USD",
    cdf: "CDF",
    currencyConversion: "Currency Conversion",
    accountNo: "ACCOUNT NUMBER",
    invoiceNo: "Invoice Number",
    startDate: "Start Date",
    endDate: "End Date",
    dueDate: "Due Date"
  })

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    let txnInfo = sessionStorage.getItem("queryData");
    txnInfo = JSON.parse(txnInfo);
    setTxnInfo({ ...txnInfo });
    if (txnInfo && txnInfo?.enableDualCurrency) {
      txnInfo["usdAmount"] = txnInfo?.amount / 100;
      fetchExchangeRates();
    }
    fetchPaymentOptions(txnInfo);
    eventEmitter('ORP', 'Payment Page Load', 'Page Load', 'Payment Page Load', null);
  }, []);
  const { languagePrefix } = useLanguage();

  const { data: data } = useFetch({
    url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
    method: GET,
    key: TRANSLATION,
    cache: {
      enabled: true,
      ttl: 3600
    },
    initialEnabled: false,
  });

  useEffect(() => {
    if(data?.payment?.paymentPageLayout) setTranslation(data?.payment?.paymentPageLayout)
  }, [data])

  const showToastMsg = (type, message) => {
    setToastMsg({ type, message });
    setTimeout(() => setToastMsg(null), 10000);
  };

  const uniqueValues = (arrayData, key) => {
    let tempArr = arrayData;
    let tempArrIndex = tempArr?.map((item) => {
      return key ? item[key]?.toLowerCase() : item?.toLowerCase();
    });
    return tempArr?.filter(function (el, index, arr) {
      return (
        index ==
        tempArrIndex?.indexOf(key ? el[key]?.toLowerCase() : el?.toLowerCase())
      );
    });
  };

  const fetchPaymentOptions = (txnInfo) => {
    // this.loaderService.startLoading();
    let postRequestData = {
      amount: txnInfo?.bundleInfo?.amount || txnInfo?.amount,
      mobileNumber: txnInfo?.msisdn,
      subscriberType: txnInfo?.bundleInfo?.lineType || txnInfo?.lineType,
      rechargeType: txnInfo?.rechargeType || "",
      // transactionType: "AIRTEL_TV"//bundleInfo?.rechargeType || '',
      transactionType: txnInfo?.transactionType || "",
    };
    let postRequest = {
      data: postRequestData,
    };
    showLoader();
    getPaymentOptions(postRequest).then(
      (res) => {
        hideLoader();
        // this.loaderService.stopLoading();
        let data;
        res = res?.data;
        if (
          res &&
          (res?.statusCode == 200 ||
            res?.status == 200 ||
            res?.status.toLowerCase() == "success")
        ) {
          if (res?.result?.paymentOption?.length > 0) {
            setPaymentOptionList(res?.result?.paymentOption);
            // removing PG if its a dualCurrency and amount is less than 1 USD
            if (txnInfo?.enableDualCurrency && txnInfo?.usdAmount < 1) {
              setPaymentOptionList(
                res?.result?.paymentOption.filter((ele) => ele.type == "AM")
              );
              setUniquePaymentOptions(
                uniqueValues(res?.result?.paymentOption, "type")
              );
              setPgOptions(
                res?.result?.paymentOption.filter((ele) => ele.type == "PG")
              );
              selectPaymentOption(
                res?.result?.paymentOption[0],
                txnInfo,
                paymentGatewayList
              );
              return;
            }
            let paymentGatewayList = res?.result?.paymentOption.filter(
              (ele) => ele.type == "PG"
            );
            setPgOptions(paymentGatewayList);
            setUniquePaymentOptions(
              uniqueValues(res?.result?.paymentOption, "type")
            );
            selectPaymentOption(
              res?.result?.paymentOption?.[0],
              txnInfo,
              paymentGatewayList
            );
          }
          eventEmitter('ORP','Payment Options Fetch Success','click','Payment Options Fetch Success',null);
        } else {
          showToastMsg("Error", res?.message || "Something Went Wrong!");
          eventEmitter('ORP','Payment Options Fetch Failed','click',res?.message,null);
        }
      },
      (error) => {
        hideLoader();
        showToastMsg(
          "Error",
          error?.error?.message || error?.message || "Something Went Wrong!"
        );
        eventEmitter('ORP','Payment Options Fetch Failed','click',error?.error?.statusCode?.toString(),null);
      }
    );
  };

  const selectPaymentOption = (
    option,
    txnData = txnInfo,
    pGOptions = pgOptions
  ) => {
    setSelectOption(option.name);
    switch (option.type && option.type.toLowerCase()) {
      case "pg": {
        setSelectedPaymentOption("pg");
        setTxnInfo({ ...txnData, pgOptions: pGOptions });
        sessionStorage.setItem(
          "queryData",
          JSON.stringify({ ...txnData, pgOptions: pGOptions }),
          true
        );
        navigate("/payment/start/card", { replaceUrl: true });
        break;
      }

      case "am": {
        setSelectedPaymentOption("am");
        navigate("/payment/start/airtel-money", { replaceUrl: true });
        break;
      }

      case "airtime": {
        setSelectedPaymentOption("airtime");
        navigate("/payment/start/airtime", { replaceUrl: true });
        break;
      }
    }
  };

  // calculate amount after exchange
  const fetchExchangeRates = () => {
    let postRequestData = {
      toCurrency: "CDF",
      amount: txnInfo?.usdAmount,
      fromCurrency: "USD",
    };
    let postRequest = {
      data: postRequestData,
    };
    showLoader();
    getExchangeRates(postRequest).then(
      (res) => {
        hideLoader()
        let data;
        res = res?.data;
        // log.info("res", res);
        if (
          res &&
          (res.statusCode == 200 ||
            res.status == 200 ||
            res.status.toLowerCase() == "success")
        ) {
          setCurrencyConversion({
            ...currencyConversion,
            cdfAmount: res?.result?.convertedAmount || "",
            usdAmount: txnInfo?.usdAmount,
          });
          selectedCurrency("usd");
          setShowCurrencyToggle(true);
        } else {
          showToastMsg(res?.message || "something went wrong!")
        }
      },
      (error) => {
        hideLoader();
        showToastMsg( error?.error?.message || "something went wrong!")
      }
    );
  };

  const selectedCurrency = (currencyType) => {
    setSelectedCurrencyUnit(currencyType);
    setConvertedAmount(currencyConversion?.[currencyType + "Amount"]);
    //  this._currencyInfo.setCurrencyInfo(currencyType, currencyType , this.currencyConversion[currencyType + 'Amount']);
  };
  return (
    <>
      <div className="payment-container">
        <div className="payment-left">
          <div className="pay-option">
            {!!uniquePaymentOptions?.length &&
              uniquePaymentOptions?.map((option, index) => (
                <div
                  className={`pay-option-item ${
                    selectOption === option?.name ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => selectPaymentOption(option)}
                >
                  {option?.name}
                </div>
              ))}
          </div>
        </div>
        <div className="payment-mid">
          <div className="payment_desktop ">
            <Routes>
              <Route path="start/card" element={<CardPayment />} />
              <Route path="start/airtime" element={<AirtimePayment />} />
              <Route
                path="start/airtel-money"
                element={<AirteMoneyPayment></AirteMoneyPayment>}
              />
              <Route path="/status/:id" element={<PaymentStatus />} />
            </Routes>
          </div>
          <div className="payment_mob">
            <div className="payment_mob_header">
              <img className="back-arrow"  onClick={()=>navigate('/')} src="assets/icons/back-arrow.svg" />
              <p>{translation?.howWillYouPage}</p>
              <div className="payment_mob_details">
                <span>{translation?.bundlePurchase} {txnInfo?.msisdn}</span>
                <span>
                  
                  {txnInfo?.bundleInfo?.description || txnInfo?.description}
                </span>
                <span>
                  
                  {txnInfo?.currency} {txnInfo?.amount}
                </span>
              </div>
            </div>
            <div className="payment-acc-wrapper">
              
            {uniquePaymentOptions?.map((option, index) => (
              <>
                {option?.type?.toLowerCase()  === "PG".toLowerCase()  ? (
                  <div className="payment-acc-group">
                    <h2> {translation?.cards}</h2>
                    <CardPayment />
                  </div>
                ) : option?.type?.toLowerCase()  === "airtime".toLowerCase() ? (
               
                    <div className="payment-acc-group">
                      <h2> {translation?.airtime}</h2>
                      <AirtimePayment />
                    </div>
               
                ) : option?.type?.toLowerCase()  === "am".toLowerCase()  ? (
                  <div className="payment-acc-group">
                    <h2> {translation?.airtelMoney}</h2>
                    <AirteMoneyPayment></AirteMoneyPayment>
                  </div>
                ) : (
                  null
                )}
              </>
            ))}
          </div>
          </div>
        </div>

        <div className="payment-right">
          <h3>{txnInfo?.rechargeType || "Bundle Purchase"}</h3>
          {txnInfo?.bundleInfo && (
            <>
              <div className="bundlePurchase_row">
                <label>{translation?.mobileNo}</label>
                <p className="ph_number">{txnInfo?.msisdn}</p>
              </div>
              <div className="bundlePurchase_row">
                <div className="plan_details">
                  <label>{translation?.planDetails}</label>
                  <span>
                    {txnInfo?.currency}
                    {txnInfo?.amount}
                  </span>
                </div>

                <div className="bundle_plan_wrap">
                  {(txnInfo?.bundleInfo?.description ||
                    txnInfo?.description) && (
                    <div className="bundle_plan_col">
                      <label>{translation?.bundle}</label>
                      <span>
                        {txnInfo?.bundleInfo?.description ||
                          txnInfo?.description}
                      </span>
                    </div>
                  )}
                  {txnInfo?.bundleInfo?.data &&
                    txnInfo?.bundleInfo?.dataUnit && (
                      <div className="bundle_plan_col">
                        <label>{translation?.benefits}</label>
                        <span>
                          {txnInfo?.bundleInfo?.data}
                          {txnInfo?.bundleInfo?.dataUnit}
                        </span>
                      </div>
                    )}
                </div>
                {showCurrencyToggle && <div>
                  <div className="item">
                    <h1>{translation?.changeCurrency}</h1>
                    <div>
                      <label>
                        <p className={(selectedCurrencyUnit.toLowerCase() == 'usd') ? 'active' : ''}
                          onClick={() => selectedCurrency('usd')}>{translation?.usd}</p>
                        <div>
                          <input type="checkbox" />
                          <span className={(selectedCurrencyUnit.toLowerCase() == 'usd') ? 'active' : ''}></span>
                        </div>
                        <p className={(selectedCurrencyUnit.toLowerCase() == 'cdf') ? 'active' : ''}
                          onClick={() => selectedCurrency('cdf')}>{translation?.cdf}</p>
                      </label>
                    </div >
                  </div >

                  <div className="item">
                    <h1>{translation?.currencyConversion}</h1>
                    <div className="conversion">
                      <p>{currencyConversion.usdAmount} {translation?.usd}</p>
                      <span>|</span>
                      <p>{currencyConversion.cdfAmount} {translation?.cdf}</p>
                    </div>
                  </div>
                </div >
                }
              </div>
            </>
          )}
          {!(txnInfo.bundleInfo && !txnInfo.invoiceDetail) && (
            <>
              <div className="bundlePurchase_row">
                <label>{translation?.mobileNo}</label>
                <p className="ph_number">{txnInfo?.msisdn}</p>
              </div>
              <div className="bundlePurchase_row">
                <label>
                  {txnInfo?.bundleInfo?.description || txnInfo?.description}
                </label>
                <div className="bundle_plan_col">
                  <strong>
                    {txnInfo?.currency} {txnInfo?.amount}
                  </strong>
                </div>
              </div>
              {/* add missing dual currency scenarios */}
            </>
          )}
          {txnInfo?.invoiceDetail && (
            <>
              <div className="bundlePurchase_row">
                <label>{translation?.accountNo}</label>
                <p className="ph_number">{txnInfo?.invoiceDetail?.accountNo}</p>
              </div>
              <div className="bundlePurchase_row">
                <label>{translation?.invoiceNo}</label>
                <p className="ph_number">{txnInfo?.invoiceDetail?.invoiceNo}</p>
              </div>
              <div className="bundlePurchase_row">
                <label>{translation?.startDate}</label>
                <p className="ph_number">
                  {txnInfo?.invoiceDetail?.billingStartDate}
                </p>
              </div>
              <div className="bundlePurchase_row">
                <label>{translation?.endDate}</label>
                <p className="ph_number">
                  {txnInfo?.invoiceDetail?.billingEndDate}
                </p>
              </div>
              <div className="bundlePurchase_row">
                <label>{translation?.dueDate}</label>
                <p className="ph_number">{txnInfo?.invoiceDetail?.dueDate}</p>
              </div>
              {/* add missing dual currency scenarios  */}
            </>
          )}
          {/* <div className="bundlePurchase_row">
            <label>MOBILE NUMBER</label>
            <p className="ph_number">{txnInfo?.msisdn}</p>
          </div>
          <div className="bundlePurchase_row">

            <div className="plan_details">
              <label>PLAN DETAILS</label>
              <span >{txnInfo?.currency}{txnInfo?.amount}</span>
            </div>

            <div className="bundle_plan_wrap">
              {(txnInfo?.bundleInfo?.description || txnInfo?.description) && <div className="bundle_plan_col">
                <label>BUNDLE</label>
                <span>{txnInfo?.bundleInfo?.description || txnInfo?.description}</span>
              </div>}
              {(txnInfo?.bundleInfo?.data && txnInfo?.bundleInfo?.dataUnit) && <div className="bundle_plan_col">
                <label>BENEFITS</label>
                <span>{txnInfo?.bundleInfo?.data} {txnInfo?.bundleInfo?.dataUnit}</span>
              </div>}
            </div>

          </div> */}
          <div className="bundlePurchase_amount">
            <span>{selectedCurrencyUnit || txnInfo?.currency}</span>
            <span>{convertedAmount || txnInfo?.amount}</span>
          </div>
        </div>
      </div>
      {toastMsg && (
        <ToastMsg
          message={toastMsg.message}
          type={toastMsg.type}
          onClose={() => setToastMsg(null)}
        ></ToastMsg>
      )}
    </>
  );
};

export default PaymentPageLayout;
