import React, { useState, useEffect } from "react";
import FormContainer from "../../../shared/components/FormContainer/FormContainer";
import { RECHARGE_TYPE } from '../../../enums/recharge-type.enum';
import BundleTable from "../../../shared/components/BundleTable";
import OtpModal from "../OtpModal";
import { fetchConfiguration } from "../../../shared/service";
import { useNavigate } from "react-router-dom";
import "./Postpaid.scss";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import useFetch from "../../../../../Shared/cache/useFetch";
import { TRANSLATION,GET ,ORP_TRANSLATION } from "../../../../../Shared/helper/constants";
import { useLanguage } from "../../../../../Shared/cache/context/LanguageProvider";

const Postpaid = () => {
    const [formData, setFormData] = useState({ msisdn: '', amount: '' })
    const [lineTypeRes, setLineType] = useState(false);
    const [msisdnLineType, setmsisdnLineType] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isBillSummary, setIsBillSummary] = useState(false);
    const [billInfo, setBillInfo] = useState({});
    const [minError, setMinError] = useState('');
    const [maxError, setMaxError] = useState('');
    const [maxAmount, setmaxAmount] = useState('');
    const [minAmount, setminAmount] = useState('');
    const [convertDecimalToHigher, setConvertDecimalToHigher] = useState(true);
    const [showNoteForAmount, setShowNoteForAmount] = useState(false);
    const navigate = useNavigate();
    const [toastMsg ,setToastMsg]=useState(null);
    const [lineTypeData,setLineTypeData]=useState();
    const [msidnError,setMsidnError]=useState('')
    const [hybridPostpaidCheck,setHybridPostpaidCheck]=useState(false)
    const [translation, setTranslation] = useState({
        postpaidBill: 'Postpaid Bill Payment',
        getYourPaymentDone: "Let's get your payment Done!",
        makePayment: "MAKE A PAYMENT",
        convertedValueMsg: "The bill amount has been converted into the next higher value and will be adjusted in the next bill amount",
        outstandingAmount: "Your outstanding amount is",
        fetchBill: "Fetch your Postpaid Bill",
    })
    const { languagePrefix } = useLanguage();
    const { data: data } = useFetch({
        url: ORP_TRANSLATION.replace('languagePrefix',languagePrefix),
        method: GET,
        key: TRANSLATION,
        initialEnabled: false,
        cache: {
          enabled: true,
          ttl: 3600
        }
      });
      useEffect(() => {
        if(data?.postpaid) setTranslation(data?.postpaid)
      }, [data])

    useEffect(() => {
        if (Object.entries(billInfo)?.length) {
            // setFormData({...formData, amount: billInfo?.amount });
            if (billInfo?.amount) minMaxErrors(billInfo?.amount);
            // setIsBillSummary(false)
        }
    }, [billInfo])

    useEffect(() => {
        let widgetInfo = sessionStorage.getItem("widgetInfo");
        widgetInfo = JSON.parse(widgetInfo);
        if((widgetInfo?.type)?.toLowerCase()=="postpaid"){
          setFormData({...formData,msisdn:widgetInfo?.msisdn})
          setHybridPostpaidCheck(true)
        }
        fetchConfig();
        eventEmitter('ORP','Postpaid Bill Payment Page Load','page load','Postpaid Recharge Page Load',null);
    }, [])

    const minMaxErrors = (amount) => {
        if (amount && amount < Number(minAmount)) {
            setMinError('Minimum amount should be')
        }
        else if (amount && amount > Number(maxAmount)) {
            setMaxError('Maximum amount should be')
        }
        else {
            setMinError('')
            setMaxError('')
            // setIsBillSummary(true)
        }
    }
    const fetchConfig = () => {
        fetchConfiguration().then(
            (res) => {
                res = res?.data;
                if (
                    res && (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
                ) {
                    setmaxAmount(res?.result?.configData?.postMaxAmnt)
                    setminAmount(res?.result?.configData?.postMinAmnt)
                    setConvertDecimalToHigher(res?.result?.configData?.beta?.amountToHigherValueFeature)
                    if (res?.result?.configData?.payStackConfig && JSON.parse(res.result?.configData?.payStackConfig).key) {
                        sessionStorage.setItem('_p_y_m_e_n_t_k_e_', JSON.parse(res.result?.configData?.payStackConfig).key, true);
                    }
                    if (res?.result?.configData) {
                        sessionStorage.setItem('_c_o_n_f_i_g', JSON.stringify(res?.result?.configData), true);
                    }
                    eventEmitter('ORP','Postpaid Bill Payment Fetch Config Success','click','Postpaid Recharge Fetch Config Success',null);
                    setHybridPostpaidCheck(res?.result?.configData?.postpaidHybridCheck)
                }
                else{
                    showToastMsg('Error',res?.message || "Something Went Wrong!")
                    eventEmitter('ORP','Postpaid Bill Payment Fetch Config Failed','click',res?.message,null);
                  }
            })
            .catch((error) => {
                showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
                eventEmitter('ORP','Postpaid Bill Payment Fetch Config Failed','click',error?.error?.statusCode?.toString(),null);
            })
    }

    const handleChange = (e) => {
        const { name } = e?.target;
        setFormData({ ...formData, [name]: e?.target?.value });
        if (e?.target?.value && e?.target?.name == "amount") { minMaxErrors(formData.amount) }
        else {
            setMinError('')
            setMaxError('')
            setShowNoteForAmount(false);
            setLineType(false);
            // setLineTypeData('')
            setIsBillSummary(false);
        }
      

    }

    const handleBillSummaryEvent = (value) => {
        setIsBillSummary(value);
    }

    const buyAirtime = () => {
        let txnData = {
            msisdn: process.env.REACT_APP_OPCO.toLowerCase() == "ga" ? "0" + formData.msisdn : formData.msisdn,
            enableDualCurrency: process.env.REACT_APP_OPCO.toLowerCase() == "cd" ? true : false,
            amount: formData.amount,
            lineType: "POSTPAID",
            transactionType: "POSTPAID_BILL",
            productCode: null,
            description: "Recharge Amount",
            currency: process.env.REACT_APP_CURRENCY,
            redirectPage: '/postpaid-bill-pay',
            rechargeType: RECHARGE_TYPE.POSTPAID_BILL_PAY,
            theme: 'theme-v1'
        };
        sessionStorage.setItem("queryData", JSON.stringify(txnData), true);
        navigate("/payment/start");
        eventEmitter('ORP','Postpaid Bill Payment Pay Bill Clicked','click','Postpaid Recharge Pay Bill Clicked',null);
    }

    const crossClick = () => {
        setFormData({ ...formData, msisdn: '' })
        setLineType(false)
    }
    const handleDataForm = (data) => {
        setLineType(data);
        setmsisdnLineType("POSTPAID")

    }
    const handleShowPopUP = () => {
        setShowModal(true)
    }

    const toggleModalFlag = (value) => {
        setShowModal(value)
    }

    const handleBillInfo = (data) => {
        setBillInfo({ amount: data?.totalOutstanding });
        setFormData({...formData,amount:(data?.totalOutstanding ? data?.totalOutstanding :0 )})
        isDecimal(data?.totalOutstanding) && convertToNextHigherValue(data?.totalOutstanding);
    }
    const isDecimal = (num) => {
        return (num % 1);
    }

    const convertToNextHigherValue = (value) => {
        if (!convertDecimalToHigher?.toLowerCase()=="true") {
            setShowNoteForAmount(false);
        }
        else {
            if (process.env.REACT_APP_OPCO !== 'DRC') {
                setFormData({ ...formData,amount: JSON.stringify(Math.ceil(value))});
                setShowNoteForAmount(true)
            }
        }
    }

    const onPackSelect = (event, bundleInfo) => {
            if (!bundleInfo) {
              return;
            }
        
            setFormData({...formData,amount:bundleInfo?.amount?.toString()});
            let txnData = {
              msisdn:  process.env.REACT_APP_OPCO?.toLowerCase() == "ga" ? "0" + formData?.msisdn : formData?.msisdn,
              enableDualCurrency:  process.env.REACT_APP_OPCO?.toLowerCase() == "cd" ? true : false,
              amount: bundleInfo?.amount,
              bundleInfo: bundleInfo,
              lineType: bundleInfo?.linetype || "POSTPAID",
              productCode:bundleInfo?.productCode,
              description:"Bundle Purchase",
              currency:bundleInfo?.currency ||process.env.REACT_APP_CURRENCY,
              redirectPage: '/postpaid-bill-pay',
              rechargeType:"BUNDLE PURCHASE",
              transactionType:(bundleInfo?.lineType || msisdnLineType ).toLowerCase() == "prepaid"  ?  "PREPAID_BUNDLES" : "POSTPAID_BUNDLES"
            };
        
            sessionStorage.setItem("queryData", JSON.stringify(txnData), true);
        
            navigate("/payment/start");
            eventEmitter('ORP','Prepaid Recharge Bundle Selected','click','Prepaid Recharge Bundle Selected',null);
    }

    const showToastMsg= (type,message)=>{
        setToastMsg({type,message});
        setTimeout(()=>setToastMsg(null),3000);
      }

    const handleLineTypeData=(data)=>{
        setLineTypeData(data);
        setmsisdnLineType(data?.result)
        if(data?.result!="POSTPAID" && (data?.result).toLowerCase()!="hybrid"){
            setMsidnError('Invalid Mobile No')
        }else{
            setMsidnError('')
        }
    }

    return (
        <>
         <div className="online-recharge">
        <div className="container">
                 <h2>{translation?.postpaidBill}</h2>
                <h4>{translation?.getYourPaymentDone}</h4>
        </div>
      </div>

      <div className="bundle-recharge-form">
        <div className="container">
          <div className="form-container">
            <FormContainer formInput={formData}
                handleChange={handleChange}
                crossClick={crossClick}
                sendDataToParent={handleDataForm}
                showBundles={process.env.REACT_APP_SHOW_POSTPAID_BUNDLES}
                sendOtpEvent={handleShowPopUP}
                showBillSummary={isBillSummary}
                maxAmount={maxAmount}
                minAmount={minAmount}
                minError={minError}
                maxError={maxError}
                showNoteForAmount={showNoteForAmount}
                sendLineTypeData={handleLineTypeData}
                billInfo={billInfo}
                msidnError={msidnError}
                hybridPostpaidCheck={hybridPostpaidCheck}
            >
            </FormContainer>
            {/* {lineTypeData?.result=='PREPAID' && <span>Invalid Moblie No </span>} */}
           
            <button className="buyAirtimeBtn" disabled={(!formData?.amount || minError || maxError || (lineTypeData?.result!="POSTPAID" && lineTypeData?.result!="HYBRID"))} onClick={buyAirtime} >MAKE A PAYMENT</button>
           
            </div>
            <div className="amountMsg">
            {showNoteForAmount && <p>{translation?.convertedValueMsg}</p>}
            {isBillSummary && <p>{translation?.outstandingAmount} {billInfo?.amount ? billInfo?.amount : 0}</p>}
            </div>
           <div className="fetch_postpaid">
           {(!isBillSummary && (lineTypeData?.result=="POSTPAID" || lineTypeData?.result=="HYBRID")) && <a onClick={handleShowPopUP} className={`${(msisdnLineType!=='POSTPAID' && msisdnLineType!=='HYBRID' )?'disabled':''}`}  >Fetch your Postpaid Bill</a>}
           </div>
            </div>
            
            </div>
            {(lineTypeRes && process.env.REACT_APP_SHOW_POSTPAID_BUNDLES =="true" && (lineTypeData?.result=="POSTPAID"||lineTypeData?.result=="HYBRID") )  && <BundleTable
                msisdn={formData?.msisdn}
                linetype={msisdnLineType}
                rechargeType="POSTPAID RECHARGE"
                buyBundleEvent={onPackSelect}

            />}
            {showModal && <OtpModal
                msisdn={formData?.msisdn}
                toggleModalEvent={toggleModalFlag}
                billSummaryEvent={handleBillSummaryEvent}
                sendBillInfo={handleBillInfo}
            />}
             {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
        </>
    )
}

export default Postpaid;
