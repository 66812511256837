/* global google */
import "./StoreLocator.scss";
import React, { useEffect, useState } from 'react'
import { getStoreLocatorTranslation, searchNearByStores } from "../../service/index";
import { getDistance } from "../../helper/helper";
import { cdnImage } from "../../helper/constants";
import Loader from "../Loader/Loader";
import { useLoading } from "../../cache/context/LoadingProvider";
import { useLocation } from 'react-router-dom';
import { sendEvent } from "../../service/eventService";
import { generateEventPageName } from "../../helper/helper";
import { useLanguage } from "../../cache/context/LanguageProvider";

const StoreLocator = () => {
  const [airtelStores, setAirtelStores] = useState([]);
  const [currentLoc, setCurrentLoc] = useState({});
  const [zoom, setZoom] = useState(6);
  const [map, setMapInstance] = useState({});
  const [errorMessage, setErrorMessage] = useState('No Data found');
  const [listOfMarkers, setListOfMarkers] = useState([]);
  const [isData, setIsData] = useState(false);
  const [isError, setIsError] = useState(false);
  const { showLoader, hideLoader } = useLoading();
  const location = useLocation();
  const { languagePrefix, selectedLanguage } = useLanguage();

  const [storeLocatorTranslation, setstoreLocatorTranslation] = useState({});
  useEffect(() => {
    fetchTranslation()
  }, [languagePrefix])

  const fetchTranslation = () => {
    getStoreLocatorTranslation(languagePrefix).then(res => {
      if (res?.data?.packageHtml){
        setstoreLocatorTranslation(JSON.parse(res?.data?.packageHtml ));
      }
    })
  }

  useEffect(() => {
    loadMap();
    sendEvent({
      "eventType": "page_view",
      "page_name": generateEventPageName(location.pathname)
    })
  }, [languagePrefix])

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let pos = {
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude
        };
        if (pos.lat && pos.lng) {
          setCurrentLoc({ lat: pos.lat, lng: pos.lng });
          map.setCenter(pos)
        }
        getNearByStores(pos?.lat, pos?.lng);
        setIsError(false)
      }, (err) => {
        setIsError(true)
        hideLoader()

      });
    }
  }

  const loadMap = () => {
    showLoader();
    let mapInstance = new google.maps.Map(document.getElementById('map'), {
      zoom: 9,
      center: {
        lat: parseFloat(process.env.REACT_APP_LAT),
        lng: parseFloat(process.env.REACT_APP_LNG)
      },
    });
    setMapInstance(mapInstance);
    let marker = new google.maps.Marker({
      map: mapInstance,
      anchorPoint: new google.maps.Point(0, -29)
    });
    // Initialize Geocoder
    let geocoder = new google.maps.Geocoder();
    //Initialize InfoWindow
    let infoWindow = new google.maps.InfoWindow({
      map: mapInstance,
      maxWidth: 320
    });
    infoWindow.close();
    // Current Location Marker
    let currentLocationMarkerImage = `${cdnImage}assets/images/map/marker.png`;
    let currentLocationMarker = new google.maps.Marker({
      icon: currentLocationMarkerImage,
      map: mapInstance,
      draggable: true,
      animation: google.maps.Animation.DROP,
      visible: false
    });
    // Events: Map Dragend
    //mapDragend(map);
    currentGeolocation(infoWindow, geocoder, currentLocationMarker, mapInstance);
    // defaultBounds for map search
    let defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(24.414950, 67.778582),
      new google.maps.LatLng(28.040770, 98.012957)
    );
    let autocompleteId = document.getElementById('autocomplete');
    var autocomplete = new google.maps.places.SearchBox( autocompleteId, {
      bounds: defaultBounds
  });
  addSearchBox(mapInstance)
  }

  function addSearchBox(map) {
    let input;
    input = document.getElementById('autocomplete');
    var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(24.414950, 67.778582), new google.maps.LatLng(28.040770, 98.012957));
    let autocomplete = new google.maps.places.SearchBox(input, {
      bounds: defaultBounds
    });
    autocomplete.addListener('places_changed', function () {

      document.getElementById('autocomplete').blur();
      var latOnSearch = map?.getCenter().lat();
      var lngOnSearch = map?.getCenter().lng();
      var places = autocomplete.getPlaces();
      if (places?.length === 0) {
        var geocoder = new google.maps.Geocoder();
        return
        // geocodeAddress(geocoder, map);
      } else {
        if (places[0].geometry) {
          latOnSearch = places[0]?.geometry?.location?.lat();
          lngOnSearch = places[0]?.geometry?.location?.lng();
          map.panTo(places[0]?.geometry?.location);
          getNearByStores(latOnSearch,lngOnSearch,100)
        }
      }
      map.setZoom(15);
    });
  }
  
  function handleLocationError(browserHasGeolocation, infoWindow, pos, data) {
    if (data && data.code == 1) {
      let blockLocation = true;
      // $scope.$apply(vm.blockLocation);
      if (blockLocation) {
        let message = "We are unable to access your Current Location. You can directly search your location from search field.";
        setErrorMessage(message);
      }
      // $timeout(function(){
      //     google.maps.event.trigger(map, 'resize');
      // });
      // $('#autocomplete').focus();
      // vm.stores=[];
    
    }
    hideLoader()
    setIsError(true)
  }


  function addMarkers(stores, map) {
    let infoWindow = new google.maps.InfoWindow();
    let Latlngbounds = new google.maps.LatLngBounds();
    let markers = [];
    //Looping through the Array and adding Markers.
    for (var i = 0; i < stores.length; i++) {
      var data = stores[i];
      var myLatlng = new google.maps.LatLng(data?.location?.latitue, data?.location?.longitude);
      //Initializing the Marker object.
      let marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title: data?.title,
        icon: `${cdnImage}assets/images/map/small-red-pin.png`,
        label: {
          text: Number(i + 1).toString(),
          color: '#fff',
          fontSize: '11px'
        },
      });
      marker.setMap(map);
      markers.push(marker);
    }
    setListOfMarkers([...listOfMarkers, ...markers]);
  }

  function currentGeolocation(infoWindow, geocoder, currentLocationMarker, map) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let pos = {
          lat: parseFloat(process.env.REACT_APP_LAT),
          lng: parseFloat(process.env.REACT_APP_LNG)
        };
        if (pos.lat && pos.lng) {
          setCurrentLoc(pos)
        }
        searchNearByStores(pos.lat, pos.lng, 100,selectedLanguage).then(
          (data) => {
            let stores = calculateDistance(data?.data, pos);
            stores?.sort((a, b) => a?.distance - b?.distance);
            setIsData(true);
            if (!stores.length) setErrorMessage('No data found')
            setAirtelStores(stores);
            for (let i = 0; i < stores.length; i++) {
              let storeAddress = stores[i].shop;
              stores[i].shop = stores[i].shop.split("-")[0];
              stores[i].selected = false;
              stores[i].latitude = stores[i].location.latitue;
              stores[i].longitude = stores[i].location.longitude;
            }
            addMarkers(stores, map);
            hideLoader();
            setIsError(false)
          }
        )
        .catch(()=>{
          hideLoader()
          setIsError(true)
        })
        getCurrentAddressByLatLong(pos.lat, pos.lng, geocoder, infoWindow, currentLocationMarker, map);
        map.setCenter(pos);

      }, function (data) {
        console.info(data);
        handleLocationError(true, infoWindow, map.getCenter(), data);
        hideLoader();
        setIsError(true)
      });
    } else {
      handleLocationError(false, infoWindow, map.getCenter());
    }
  }

  function getCurrentAddressByLatLong(lat, lng, geocoder, infoWindow, currentLocationMarker, map) {
    let currentPosition = new google.maps.LatLng(lat, lng);
    if (currentLocationMarker) {
      currentLocationMarker.setVisible(false);
      currentLocationMarker.setPosition(currentPosition);
    }
    if (!currentLocationMarker) {
      currentLocationMarker = new google.maps.Marker({
        position: currentPosition,
        icon: `${cdnImage}assets/images/map/marker.png`,
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        visible: false
      });
    }
    currentLocationMarker.setVisible(true);
    currentLocationMarker.setMap(map);
    // map.setZoom(13);
    // geocodePosition(geocoder, currentPosition, showSelectedCity);
  }

  const getNearByStores = (lat, lng) => {
    showLoader();
    searchNearByStores(lat, lng, 100).then(
      (data) => {
        hideLoader();
        let pos={lat,lng}
        let stores = calculateDistance(data?.data,pos);
        stores?.sort((a, b) => a?.distance - b?.distance);
        setAirtelStores(stores);
        setIsData(true);
        if (!stores.length) setErrorMessage('No Data Found')
      }
    )
  }



  const calculateDistance = (stores, pos = currentLoc) => {
    return stores.map(store => {
      const start_lat = pos?.lat;
      const start_lng = pos?.lng;
      store.distance = getDistance(start_lat, start_lng, store?.location?.latitue, store?.location?.longitude);
      store.distance = Number(store?.distance);
      return store
    })
  }
  const smoothZoom = (max, cnt) => {
    if (cnt >= max) {
      return;
    } else {
      setTimeout(
        () => setZoom(cnt),
        200
      );
      smoothZoom(max, cnt + 1);
    }
  }
  const changeMarkerImage = (location, index, isHover) => {
    if (isHover) {
      map.setCenter({ lat: parseFloat(location.latitue), lng: parseFloat(location.longitude) });
      // setCenter({ lat: parseFloat(location?.latitue), lng: parseFloat(location?.longitude) });
      smoothZoom(13, zoom);
      listOfMarkers[index]?.setIcon(`${cdnImage}assets/images/map/large-red-pin.png`);

    } else {
      listOfMarkers[index]?.setIcon(`${cdnImage}assets/images/map/small-red-pin.png`);

    }

  }

  return (
    <>
      <div className="store-locator">
        {!isData && <Loader></Loader>}
        <div className="search-bar">
          <div className="search-bar-inner"> 
          <div className="row">
            <div className="col-sm-3 pr-0">
              <h3 className="color-md-grey find">{storeLocatorTranslation?.findAirtelIn}
              </h3>
            </div>
            <div className="col-md-6 col-sm-5">
              <div className="search-field">
                <input id="autocomplete" type="text" name="" className="fs-31 height-auto form-control pr-30 " placeholder={storeLocatorTranslation?.cityPincode} />
                <span className="cursor-pointer autocomplete-search tp-14" ><i className="icon-outlined-magnifier"></i></span>
              </div>
            </div>
            <div className="col-md-3 col-sm-5 crt-loc-btn">
              <span className="or-text fs-18 pd-rht-5">{storeLocatorTranslation?.or}</span>
              <button className="btn border-0" onClick={getCurrentLocation}>{storeLocatorTranslation.useCurrentLocation}</button>
            </div>
          </div></div>
        </div>
        {!isError ?(<div className="map-list-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className="list-container">
                  {!!airtelStores.length && airtelStores.map((store, index) => (
                    <div className="col-xs-12 card-info" key={index} onMouseEnter={() => { changeMarkerImage(store.location, index, true) }} onMouseLeave={() => { changeMarkerImage(store.location, index, false) }}>
                      <div className="card-head">
                        <div className="left">  <span className="border-none mr-10 circle-red"></span>{index + 1}. {store.shop}</div>
                        <div className='distance'>
                          <h3 className="font-size-30">{store.distance} Km
                          </h3>
                          <a className="link link-arrow pull-right" />
                        </div>
                      </div>

                      <div className="card-body" ng-if="store.phyLocation">{store.phyLocation}</div>
                      {store?.phNumber && < label className="margin-top-20" >
                        <i className="phone-icon icon center-block icon-outlined-phone icon-size-20 padding-bottom-20">
                          <span className="margin-left-5"><a >{store.phNumber}</a></span>
                        </i>
                      </label>}
                    </div>
                  ))}


                  {/* {errorMessage && !airtelStores.length && <div className="errorMessage">{errorMessage}
                  </div>} */}

                </div> </div>
              <div className="col-sm-8">
                <div id='map'></div>  </div>
            </div>

          </div>
        </div>):<>
        {isError && !airtelStores.length && <div class="location-not-found">
          <div className="container">
            <div class="col-sm-8 p-0"><span id="width_tmp_select" class="fs-20"></span>
              <h2 class="color-md-grey mb-30 hidden-xs">{storeLocatorTranslation?.noResults}</h2>
              <h2 class="mb-10 visible-xs">{storeLocatorTranslation?.noResults}</h2><p class="color-grey font-size-20 mt-20"><span class="hidden-xs">{storeLocatorTranslation?.noMatchingResult}<br />{storeLocatorTranslation?.tryAgain}</span> <span class="visible-xs">{storeLocatorTranslation?.noResultMobile}<br />{
              storeLocatorTranslation?.noResultMobile}</span>
              </p></div><div class="col-sm-4"><img height="240px" class="hidden-xs" src={`${cdnImage}assets/images/illustration-search-files.svg`} />
               <img height="240px" class="visible-xs pull-right" src={`${cdnImage}assets/images/illustration-search-files.svg`} /></div></div>

        </div>}
        </>}
      </div>
    </>
  );
};
export default StoreLocator;